<div class="container">
  <div class="row">
    <div class="col-12">
      <a [routerLink]="['/explore/featured']" class="back-button d-flex align-items-center">
        <img src="/assets/images/icons/ic_chevron_left.svg" [alt]="'APP.PROFILE.BACK' | translate">
        <h4>
          <small>
            {{ 'APP.PROFILE.BACK' | translate | uppercase }}
          </small>
        </h4>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 user__wrapper">
      <div class="d-flex">
        <div *ngIf="user.profile_picture_url" class="col-lg-6 col-md-6 col-sm-12 profile-picture"
          [ngStyle]="{ background: 'url(' + user.profile_picture_url + ')'}">
        </div>
        <div *ngIf="!user.profile_picture_url" class="col-lg-6 col-md-6 col-sm-12 profile-picture"
          [ngStyle]="{ background: 'url(' + user.cover_picture_url + ')'}">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-between">
          <div class="content">
            <h3>{{ user.business_name }}</h3>
            <h4 class="position">
              <b>
                {{ user.campaign }}
              </b>
            </h4>
          </div>
          <div *ngIf="user.hometown || user.country" class="content">
            <h4>
              <small>
                {{ 'MODELS.USER.CITY' | translate | uppercase }}
              </small>
            </h4>
            <p>
              <span *ngIf="user.hometown">{{ user.hometown }}, </span>{{ user.country.name }}
            </p>
          </div>
          <div class="content social__wrapper">
            <h4>
              <small>
                {{ 'APP.PROFILE.FOLLOW_US' | translate | uppercase }}
              </small>
            </h4>
            <div class="d-flex">
              <a target="_blank" href="https://www.facebook.com/{{ user.facebook_user }}">
                <small>
                  {{ 'Facebook' | uppercase }}
                </small>
              </a>
              <a target="_blank" href="https://www.instagram.com/{{ user.instagram_user }}">
                <small>
                  {{ 'Instagram' | uppercase }}
                </small>
              </a>
              <a target="_blank" href="https://www.twitter.com/{{ user.twitter_user }}">
                <small>
                  {{ 'Twitter' | uppercase }}
                </small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="user.description" class="col-lg-5 col-sm-12 col-md-12 offset-lg-1 description__wrapper">
      <h4>
        <small>
          {{ 'APP.PROFILE.ABOUT_US' | translate | uppercase }}
        </small>
      </h4>
      <p>
        {{ user.description }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="separator"></div>
    </div>
    <div *ngIf="user.youtube_video_id[currentLanguage] && user.youtube_video_id[currentLanguage] !== ''"
      class="col-4 offset-4">
      <button class="btn btn-primary screen"
        [ngStyle]="{ 'background-image': 'url(https://img.youtube.com/vi/' + user.youtube_video_id[currentLanguage] + '/maxresdefault.jpg)'}"
        style="width:100%; height: 198px; background-size: cover; background-repeat: no-repeat;" (click)="openModal()">
        <i class="fab fa-youtube p-2" style="background-color: rgba(2,15,255,1); color: white; font-size: 75px;"></i>
      </button>
    </div>
  </div>
</div>

<ngx-smart-modal #businessProfileVideoModal customClass="custom-modal" identifier="businessProfileVideoModal"
  target="body">
  <button type="button" class="close-button" (click)="modal.closeLatestModal()">
    <img src="/assets/images/icons/ic_close.svg" [alt]="'COMMON.CLOSE' | translate | capitalize">
  </button>
  <div class="modal-body mt-3">
    <iframe width="100%" height="430" [src]='videoUrl()' frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</ngx-smart-modal>
