import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private cookies: CookieService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token: string = route.data.tokenName;

    if (!this.cookies.get(token)) {
      if (token.includes('tl-landing')) {
        this.router.navigate(['/'], { queryParams: { login: true } });
      } else {
        this.router.navigate(['/u/login']);
      }
      return false;
    }
    return true;
  }
}
