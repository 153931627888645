import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { User } from '@models/user'
import { Toastr } from '@shared/toastr/toastr.service'
import { VisitedPlacesService } from '@shared/services/visited-places.service'
import { Place } from '@models/place'
import { Pagination } from '@models/pagination'
import { UsersService } from '@shared/services/users.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-user-aside',
  templateUrl: './aside.component.html',
})
export class AsideComponent implements OnInit, OnDestroy {
  @Input() user: User = new User()

  currentUser: User = new User()
  suggestedPlaces: Array<Place> = []
  placesPagination: Pagination = new Pagination()
  subscriptions: Subscription = new Subscription()

  constructor(
    private _visitedPlaces: VisitedPlacesService,
    private toastr: Toastr,
    private usersService: UsersService,
  ) {}

  ngOnInit() {
    this.getCurrentUser()
    this.getPlaces()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  getCurrentUser() {
    this.subscriptions.add(
      this.usersService.current.subscribe((user) => {
        this.currentUser = user
      }),
    )
  }

  getGuestSuggestedPlaces() {
    const page = this.placesPagination.nextPage || 1

    this._visitedPlaces.getGuestSuggestedPlaces(this.user, page).subscribe(
      (response) => {
        this.placesPagination = new Pagination(response['meta']['pagination'])

        response['places'].forEach((place) => this.suggestedPlaces.push(place))
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  getPlaces() {
    if (this.currentUser.id !== '') {
      this.getSuggestedPlaces()
    } else {
      this.getGuestSuggestedPlaces()
    }
  }

  getSuggestedPlaces() {
    // TODO: Change this to the worth the visit places for Local Tourists
    const page = this.placesPagination.nextPage || 1

    this._visitedPlaces.getSuggestedPlaces(page).subscribe(
      (response) => {
        this.placesPagination = new Pagination(response['meta']['pagination'])

        response['places'].forEach((place) => this.suggestedPlaces.push(place))
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }
}
