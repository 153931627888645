<a [routerLink]="['/collections/', collection.id]" class="collection-card">
  <div class="screen">
    <div class="image-wrapper" [ngStyle]="{ backgroundImage: 'url(' + collection.picture_url + ')' }">
    </div>
  </div>
  <div ngbDropdown class="content__wrapper custom-dropdown" placement="bottom-right">
    <h4>{{ collection.name }}</h4>
    <button type="button" class="options" ngbDropdownToggle click-stop-propagation>
      <img src="/assets/images/icons/ic_dots.svg" alt="">
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <ul>
          <li>
            <button type="button" (click)="editCollection.emit(collection)" click-stop-propagation>
              {{ 'FORMS.ACTIONS.EDIT' | translate }}
            </button>
          </li>
          <li>
            <button type="button" (click)="deleteCollection.emit(collection)" click-stop-propagation>
              {{ 'FORMS.ACTIONS.DELETE' | translate }}
            </button>
          </li>
          <li ngbDropdown class="custom-dropdown" placement="right">
            <button type="button" ngbDropdownToggle click-stop-propagation>
              {{ 'COMMON.SHARE' | translate | capitalize }}
            </button>
            <div ngbDropdownMenu aria-labelledby="shareMenu">
              <!-- <div class="content">
                <a [href]="whatsappUrl()" target="_blank" class="d-block">
                  <img width="30px" src="/assets/images/common/logo_whatsapp.png" alt="">
                  WhatsApp
                </a>
                <button class="py-0 mt-2 d-sm-block d-lg-none" shareButton="messenger">
                  <img width="30px" class="imgSharing" src="/assets/images/common/logo_messenger.png" alt="">
                  Messenger
                </button>
                <a [href]="fbUrl()" target="_blank" class="d-none d-lg-block">
                  <img width="30px" src="/assets/images/common/logo_messenger.png" alt="">
                  Messenger
                </a>
                <a [href]="mailUrl()" target="_blank" class="d-block">
                  <img width="30px" class="imgSharing" src="/assets/images/icons/ic_mail_black.svg" alt="">
                  {{ 'MODELS.USER.EMAIL' | translate | capitalize }}
                </a>
                <button type="button" class="py-0 d-block" ngxClipboard [cbContent]="getUrl()" (cbOnSuccess)="copiedToClipboard()">
                  <img width="30px" class="imgSharing" src="/assets/images/icons/ic_link_black.svg" alt="">
                  {{ 'APP.TRIPLISTS.COPY_LINK' | translate }}
                </button>
              </div> -->
              <ul>
                <li>
                  <share-button [theme]="'material-light'" [button]="'messenger'" [showText]="true" [url]="getUrl()" [autoSetMeta]="false" click-stop-propagation>
                  </share-button>
                </li>
                <li>
                  <share-button [theme]="'material-light'" [button]="'whatsapp'" [showText]="true" [url]="getUrl()" [autoSetMeta]="false" click-stop-propagation>
                  </share-button>
                </li>
                <li>
                  <share-button [theme]="'material-light'" [button]="'email'" [showText]="true" [url]="getUrl()" [autoSetMeta]="false" click-stop-propagation>
                  </share-button>
                </li>
                <li>
                  <share-button [theme]="'material-light'" [button]="'copy'" [showText]="true" [url]="getUrl()" [autoSetMeta]="false" click-stop-propagation>
                  </share-button>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </button>
  </div>
</a>
