import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { User } from '@models/user'
import { Collection } from '@models/collection'
import { Triplist } from '@models/triplist'

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
})
export class BusinessProfileComponent implements OnInit {
  @Input() user: User = new User()
  @Input() collections: Array<Collection> = []
  @Input() triplists: Array<Triplist> = []
  @Output() getTriplists: EventEmitter<object> = new EventEmitter()

  activeTab: string = 'triplists'

  constructor() {}

  ngOnInit() {}

  toggleTab(tabName: string) {
    this.activeTab = tabName
  }
}
