interface GuestInterface {
  canEdit: boolean;
  email: string;
  id: string;
}

export class Guest implements GuestInterface {
  canEdit: boolean;
  email: string;
  id: string;

  constructor(attrs = {}) {
    this.canEdit = attrs['can_edit'] || false;
    this.email = attrs['email'] || '';
    this.id = attrs['id'] || '';
  }
}