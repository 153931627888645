<ngx-smart-modal #createPlaceRecommendedByModal customClass='custom-modal create-place-recommended-by-modal' identifier="createPlaceRecommendedByModal" target="body" (onAnyCloseEvent)="cancel()" style='width: 100%;'>
  <div class="modal-body">
    <form [formGroup]="newPlaceRecommendedByForm" (ngSubmit)="onSubmit(newPlaceRecommendedByForm)">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-2">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 text-center">
                  <h3 class="mb-3">
                    {{ 'APP.PLACES.REGISTER_NEW' | translate }}
                  </h3>
                </div>
                <div class="col-4 text-center cover-picture screen">
                  <div class="cover-picture" [ngStyle]="{ backgroundImage: 'url(' + image + ')' }" (click)="imgUploader.click()">
                    <h4 *ngIf="image == null">
                    </h4>
                    <span>
                      <button type="button" class="button--link">
                        {{ 'FORMS.ACTIONS.CHANGE' | translate }}
                      </button>
                    </span>
                    <input type="file" name="cover_picture" id="cover_picture" (change)="fileChanged($event)" #imgUploader hidden>
                  </div>
                </div>
                <div class="col-8">
                  <div class="col-12">
                    <div class="form-group w-100">
                      <app-input type="text" id="name" [parentForm]="newPlaceRecommendedByForm" [labelName]="'DASHBOARD.PLACES.NAME' | translate">
                      </app-input>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group w-100">
                      <app-input type="text" id="maps_id" [parentForm]="newPlaceRecommendedByForm" [labelName]="'DASHBOARD.PLACES.ENTER_PLACE_ADDRESS' | translate">
                      </app-input>
                    </div>
                  </div>
                  <div class="col-6 d-inline-block">
                    <div class="form-group w-100">
                      <label for="country_id" class="text-uppercase"> {{ 'DASHBOARD.PLACES.COUNTRY' | translate }}
                      </label>
                      <select class="form-select" name="country_id" id="country_id" formControlName="country_id" [ngClass]="{'is-invalid': newPlaceRecommendedByForm.get('country_id').hasError('required') && isSubmitted }" (change)="countryChanged(newPlaceRecommendedByForm.get('country_id').value)">
                        <option value="" disabled> {{ 'DASHBOARD.PLACES.SELECT_COUNTRY' | translate }} </option>
                        <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6 d-inline-block">
                    <div class="form-group w-100">
                      <label for="destination_id" class="text-uppercase">{{ 'DASHBOARD.PLACES.DESTINATION' | translate }}</label>
                      <select class="form-select" name="destination_id" id="destination_id" formControlName="destination_id" [ngClass]="{'is-invalid': newPlaceRecommendedByForm.get('destination_id').hasError('required') && isSubmitted }" (change)="destinationChanged(newPlaceRecommendedByForm.get('destination_id').value)">
                        <option value="" disabled> {{ 'DASHBOARD.PLACES.SELECT_CITY' | translate }} </option>
                        <option *ngFor="let destination of destinations" [value]="destination.id">{{destination.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6 d-inline-block">
                    <div class="form-group w-100">
                      <label for="neighborhood_id" class="text-uppercase">
                        {{ 'DASHBOARD.PLACES.NEIGHBORHOOD' | translate }} </label>
                      <select class="form-select" name="neighborhood_id" id="neighborhood_id" formControlName="neighborhood_id" [ngClass]="{'is-invalid': newPlaceRecommendedByForm.get('neighborhood_id').hasError('required') && isSubmitted }">
                        <option value="" disabled> {{ 'DASHBOARD.PLACES.SELECT_NEIGHBORHOOD' | translate }} </option>
                        <option *ngFor="let neighborhood of neighborhoods" [value]="neighborhood.id">
                          {{neighborhood.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6 d-inline-block">
                    <div class="form-group w-100">
                      <label for="classification" class="text-uppercase">
                        {{ 'DASHBOARD.PLACES.CLASSIFICATION' | translate }} </label>
                      <select class="form-select" name="classification" id="classification" formControlName="classification" [ngClass]="{'is-invalid': newPlaceRecommendedByForm.get('classification').hasError('required') && isSubmitted }">
                        <option value="" disabled>{{ 'DASHBOARD.PLACES.SELECT_CLASSIFICATION' | translate }}</option>
                        <option value="0"> {{ 'DASHBOARD.PLACES.FAMILY' | translate }} </option>
                        <option value="1"> {{ 'DASHBOARD.PLACES.ADULTS_ONLY' | translate }} </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group w-100">
                      <!-- <app-input type="text" id="phone_number" [parentForm]="newPlaceRecommendedByForm"
                        [labelName]="'DASHBOARD.PLACES.PHONE_NUMBER' | translate">
                      </app-input> -->
                      <app-input id="phone_number" customClass="--no-margin" type="international-phone-number" [labelName]="'MODELS.USER.PHONE' | translate | capitalize" [parentForm]="newPlaceRecommendedByForm" [placeholder]="'MODELS.USER.PHONE' | translate | capitalize">
                      </app-input>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row justify-content-around">
                    <div class="col-3">
                      <button type="button" class="button--stroke" (click)="cancel()">
                        {{ 'FORMS.ACTIONS.CANCEL' | translate }}
                      </button>
                    </div>
                    <div class="col-3">
                      <button class="button--primary" type="submit" [disabled]="isLoading">{{ 'FORMS.ACTIONS.ADD' | translate }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
</ngx-smart-modal>
