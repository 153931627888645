<ngx-smart-modal
  #createCollectionModal
  [customClass]="'create-collection-modal nsm-centered'"
  identifier="createCollectionModal"
  (onOpen)="getCollection()"
  (onAnyCloseEvent)="reset()">
  <div class="container">
    <div class="row title-container">
      <div class="col-12">
        <h3 class="text-center">
          {{ (isEdit ? 'APP.EDIT_COLLECTION.TITLE' : 'APP.NEW_COLLECTION.TITLE') | translate }}
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form
          class="row"
          [formGroup]="newCollectionForm"
          (ngSubmit)="onSubmit(newCollectionForm)"
          novalidate>
          <div class="col-12">
            <div class="screen">
              <div class="profile-picture"
                [ngStyle]="{ backgroundImage: 'url(' + image + ')' }"
                (click)="imgUploader.click()">
                <h4 *ngIf="image == null">
                </h4>
                <span>
                  <button type="button" class="button--link">
                    {{ 'FORMS.ACTIONS.CHANGE' | translate }}
                  </button>
                </span>
                <input
                  type="file"
                  name="picture"
                  id="picture"
                  (change)="fileChanged($event)"
                  #imgUploader
                  hidden>
              </div>
            </div>
          </div>
          <div class="col-12">
            <app-input
              id="name"
              [parentForm]="newCollectionForm"
              [labelName]="'APP.NEW_COLLECTION.FORM.NAME_LABEL' | translate"
              [placeholder]="'APP.NEW_COLLECTION.FORM.NAME_PLACEHOLDER' | translate">
            </app-input>
          </div>
          <div class="col-12 d-flex justify-content-between actions-container">
            <button
              class="button--stroke"
              type="button"
              (click)="closeModal()">
              {{ 'FORMS.ACTIONS.CANCEL' | translate }}
            </button>
            <button
              class="button--primary"
              type="submit">
              {{ (isEdit ? 'APP.EDIT_COLLECTION.FORM.SUBMIT' : 'APP.NEW_COLLECTION.FORM.SUBMIT') | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ngx-smart-modal>
