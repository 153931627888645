import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-delete-collection',
  templateUrl: './delete-collection.component.html',
})
export class DeleteCollectionComponent implements OnInit {
  @Output() confirmDelete: EventEmitter<object> = new EventEmitter();
  @Input() isLoading: boolean;

  constructor(public _modal: NgxSmartModalService) {}

  ngOnInit() {}
}
