import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Router, CanActivate } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable()
export class UnauthGuard implements CanActivate {
  constructor(
    private cookies: CookieService,
    private router: Router
  ) {}

  canActivate() {
    if (this.cookies.get(environment.COOKIE.DASHBOARD)) {
      this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }
}
