export class FormUtilities {
  static generateFormData(form, namespace?: string, previousFormData?) {
    let formData = previousFormData || new FormData()
    let formKey

    for (let control in form.controls) {
      const value = form.get(control).value

      if (value != null) {
        if (namespace) {
          formKey = namespace + `[${control}]`
        } else {
          formKey = control
        }

        // If value is a nested object but not a file, use recursivity
        if (typeof value === 'object' && !(value instanceof File)) {
          if (Array.isArray(value)) {
            // If value is array append each item
            value.forEach((newValue) => {
              Object.keys(newValue).forEach((newValueKey) => {
                formData.append(
                  formKey + `[][${newValueKey}]`,
                  newValue[newValueKey],
                )
              })
            })
          } else {
            this.generateFormData(form.controls[control], formKey, formData)
          }
        } else {
          formData.append(formKey, value)
        }
      }
    }

    return formData
  }
}
