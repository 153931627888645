import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'

@Injectable({ providedIn: 'root' })
export class LocationsService {
  constructor(private httpClient: HttpClient) {}

  createPlaceRecommendedBy(place: FormData) {
    return this.httpClient.post(
      `${environment.API}/places/recommended_by`,
      place,
    )
  }

  getAllCountries() {
    return this.httpClient.get(`${environment.API}/countries`)
  }

  getAllDestinations(
    q: string = '',
    interestId: string = '',
    page: string = '1',
  ) {
    const params = { q: q, interest_id: interestId, page: page }

    return this.httpClient.get(`${environment.API}/destinations`, {
      params: params,
    })
  }

  getAllNeighborhoods(q_adv: string = '') {
    const params = { q_adv }

    return this.httpClient.get(`${environment.API}/neighborhoods`, { params })
  }

  getDestination(id: string) {
    return this.httpClient.get(`${environment.API}/destinations/${id}`)
  }

  getDestinationsByCountry(id: string) {
    return this.httpClient.get(
      `${environment.API}/countries/${id}/destinations`,
    )
  }

  getGuestNeighborhood(id: string) {
    return this.httpClient.get(`${environment.API}/guest_neighborhoods/${id}`)
  }

  getGuestPlace(id: string) {
    return this.httpClient.get(`${environment.API}/guest_places/${id}`)
  }

  getGuestPlacesByNeighborhood(
    id: string,
    q: string = '',
    interestId: string = '',
  ) {
    const params = { q: q, interest_id: interestId }

    return this.httpClient.get(
      `${environment.API}/guest_neighborhoods/${id}/places`,
      { params: params },
    )
  }

  getNeighborhoodsByDestination(
    id: string,
    q: string = '',
    interestId: string = '',
    page: string = '1',
  ) {
    const params = { q: q, interest_id: interestId, page: page }

    return this.httpClient.get(
      `${environment.API}/destinations/${id}/neighborhoods`,
      { params: params },
    )
  }

  getPlacesByDestination(id: string) {
    return this.httpClient.get(`${environment.API}/destinations/${id}/places`)
  }

  getNeighborhood(id: string) {
    return this.httpClient.get(`${environment.API}/neighborhoods/${id}`)
  }

  getPlacesByNeighborhood(id: string, q: string = '', interestId: string = '') {
    const params = { q: q, interest_id: interestId }

    return this.httpClient.get(
      `${environment.API}/neighborhoods/${id}/places`,
      { params: params },
    )
  }

  getPlace(id: string) {
    return this.httpClient.get(`${environment.API}/places/${id}`)
  }

  getSuggestedNeighborhoods() {
    return this.httpClient.get(`${environment.API}/suggested_neighborhoods`)
  }
}
