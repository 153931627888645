import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core'
import { Place } from '@models/place'
import { BookmarksService } from '@shared/services/bookmarks.service'
import { Toastr } from '@shared/toastr/toastr.service'
import { CookieService } from 'ngx-cookie'
import { Bookmark } from '@models/bookmark'
import { TranslateService } from '@ngx-translate/core'
import { UsersService } from '@shared/services/users.service'
import { Subscription } from 'rxjs'
import { environment } from '@environments/environment'
import { User } from '@models/user'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { ApplicationService } from '../../../../services/application.service'
import { CollectionsService } from '../../../../services/collections.service'
import { Collection } from '../../../../models/collection'
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-place-card',
  templateUrl: './place-card.component.html',
})
export class PlaceCardComponent implements OnInit, OnDestroy {
  @Input() place: Place = new Place()
  @Output() deleteBookmarkCallback: EventEmitter<object> = new EventEmitter()
  @Input() ctaQueryParams: object = {}
  @ViewChild('collectionMenu', { static: true }) collectionMenu: NgbDropdown

  bookmarked: boolean = false
  hovered: boolean = false
  isBookmarked = false
  language: string = this.cookies.get('tl-lang') || 'en'
  subscriptions: Subscription = new Subscription()
  userCollections: Array<Collection> = []
  updatedDescription: string
  user: User = new User()
  whatsappMsg: string

  constructor(
    private _bookmarks: BookmarksService,
    private applicationService: ApplicationService,
    private collectionsService: CollectionsService,
    private cookies: CookieService,
    public modal: NgxSmartModalService,
    private translateService: TranslateService,
    private toastr: Toastr,
    private usersService: UsersService,
  ) {}

  ngOnInit() {
    const hasSession = this.cookies.get(environment.COOKIE.LANDING)

    if (hasSession) {
      this.getUser()
    }

    this.subscriptions.add(
      this.applicationService.currentLanguage.subscribe((language) => {
        this.updatedDescription = this.place.description[language]
      }),
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  addToBookmarks() {
    const body = { place_id: this.place.id }

    this._bookmarks.createBookmark(body).subscribe(
      (response: any) => {
        this.bookmarked = true
        this.place.bookmark = response.bookmark
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  bookmarkedMessage() {
    const message = this.hovered ? 'REMOVE_BOOKMARK' : 'BOOKMARKED'
    return this.translateService.instant('APP.EXPLORE.' + message)
  }

  checkBookmark() {
    this.bookmarked = this.place.is_bookmarked
  }

  copiedToClipboard() {
    let message = this.translateService.instant('COMMON.COPIED')

    this.toastr.success(message)
  }

  deleteBookmark() {
    this.subscriptions.add(
      this._bookmarks.deleteBookmark(this.place.bookmark.id).subscribe(
        (_) => {
          this.isBookmarked = false
          this.place.is_bookmarked = false

          this.userCollections.forEach((collection) => {
            collection.places = collection.places.filter(
              (place) => place.id !== this.place.id,
            )
          })
        },
        (errors) => {
          this.toastr.error(errors)
        },
      ),
    )
  }

  fbUrl() {
    return `http://www.facebook.com/dialog/send?app_id=664848067549895&link=${window.location.href}/places/${this.place.id}&redirect_uri=${window.location.href}/places/${this.place.id}`
  }

  getUrl() {
    return `${window.location.href}/places/${this.place.id}`
  }

  getUser() {
    this.usersService.current.subscribe((user) => {
      this.user = user
    })
  }

  getUserCollections() {
    this.subscriptions.add(
      this.collectionsService.getUserCollections().subscribe(
        (response) => {
          this.userCollections = response['collections'].map(
            (collection) => new Collection(collection),
          )
        },
        (errors) => {
          this.toastr.error(errors)
        },
      ),
    )
  }

  handleBookmark() {
    if (this.user.id !== '') {
      if (this.bookmarked) {
        this.removeFromBookmarks()
      } else {
        this.addToBookmarks()
      }
    } else {
      this.modal.get('signUpModal').open()
    }
  }

  handleClick() {
    if (this.user.id !== '') {
      if (this.isBookmarked) {
        this.deleteBookmark()
      } else {
        this.saveToBookmarks()
        this.collectionMenu.open()
      }
    } else {
      this.modal.get('signUpModal').open()
    }
  }

  mailUrl() {
    return `mailto:?subject=&body=${window.location.href}/places/${this.place.id}`
  }

  setUrl() {
    return ['places', this.place.id, this.ctaQueryParams]
  }

  removeFromBookmarks() {
    if (this.bookmarked) {
      this._bookmarks.deleteBookmark(this.place.bookmark.id).subscribe(
        (response) => {
          this.bookmarked = false

          this.deleteBookmarkCallback.emit()
        },
        (errors) => {
          this.toastr.error(errors)
        },
      )
    }
  }

  saveToBookmarks() {
    const body = { place_id: this.place.id }

    this.subscriptions.add(
      this._bookmarks.createBookmark(body).subscribe(
        (response: any) => {
          this.isBookmarked = true
          this.place.bookmark = response.bookmark
          const message = this.translateService.instant('FORMS.MESSAGES.SAVED')

          this.toastr.success(message)
        },
        (errors) => {
          this.isBookmarked = false
          this.toastr.error(errors)
        },
      ),
    )
  }

  // NOTE: We hace 3 different cases:
  // 1. Place is not bookmarked and we want to bookmark it and add it to a collection
  // 2. Place is bookmarked and we want to add it to a collection
  // 3. Place is bookmarked and we want to delete bookmark from a collection
  saveToCollection(collectionId: string) {
    const bookmark = this.place.bookmark
    let userCollection = this.userCollections.find((collection) => {
      return collection.id === collectionId
    })

    if (!this.place.is_bookmarked) {
      let payload = {
        collection_id: collectionId,
        place_id: this.place.id,
      }

      this.subscriptions.add(
        this._bookmarks.createBookmark(payload).subscribe(
          (response: any) => {
            this.isBookmarked = true
            this.place.is_bookmarked = true
            this.place.bookmark = response.bookmark

            userCollection.places.push(this.place)
          },
          (errors) => {
            alert('Bookmark could not be created')
          },
        ),
      )

      return
    }

    if (!userCollection.hasPlace(this.place)) {
      const payload = { collection_id: collectionId }
      this.subscriptions.add(
        this._bookmarks.updateBookmark(bookmark.id, payload).subscribe(
          (response) => {
            userCollection.places.push(this.place)
          },
          (errors) => {
            this.toastr.error(errors)
          },
        ),
      )
    } else {
      this.subscriptions.add(
        this._bookmarks
          .deleteBookmarkFromCollection(bookmark.id, collectionId)
          .subscribe(
            (response) => {
              userCollection.places = userCollection.places.filter(
                (place) => place.id !== this.place.id,
              )
            },
            (errors) => {
              alert('errors')
            },
          ),
      )
    }
  }

  whatsappUrl() {
    if (this.language === 'en') {
      this.whatsappMsg = 'I want to share this place with you...'
    } else if (this.language === 'es-MX') {
      this.whatsappMsg = 'Quiero compartir este lugar contigo...'
    }
    return `https://web.whatsapp.com/send?text=${this.whatsappMsg}${window.location.href}/places/${this.place.id}`
  }
}
