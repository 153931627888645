import { Component, OnInit, Input } from '@angular/core';
import { Triplist } from '@models/triplist';
import { Collection } from '@models/collection';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html'
})
export class UserDataComponent implements OnInit {
  @Input() activeTab: string = 'triplists';
  @Input() collections: Array<Collection> = [];
  @Input() triplists: Array<Triplist> = [];

  constructor() {}

  ngOnInit() {}

  duplicatedCallback(triplist: Triplist) {
    this.triplists.unshift(triplist);
  }

  removeTriplist(id: string) {
    this.triplists = this.triplists.filter(triplist => triplist.id != id);
  }
}
