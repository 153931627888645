<a [routerLink]="['/featured', businessProfile.id]" class="local-tourist-card">
  <app-user-profile-picture [user]="businessProfile"></app-user-profile-picture>
  <div class="local-tourist__info-wrapper">
    <h4>{{ businessProfile.business_name }}</h4>
    <p> {{ businessProfile.campaign }} </p>
    <h4>
      <small>
        {{ getLocation() }}
      </small>
    </h4>
  </div>
  <button *ngIf="followVisible && !businessProfile.is_followed" type="button" class="button--stroke" (click)="follow()"
    click-stop-propagation>
    {{ 'APP.EXPLORE.LOCAL_TOURISTS.FOLLOW' | translate }}
  </button>
  <button *ngIf="followVisible && businessProfile.is_followed" type="button" class="button--primary"
    (click)="unFollow()" click-stop-propagation>
    {{ 'APP.EXPLORE.LOCAL_TOURISTS.UNFOLLOW' | translate }}
  </button>
</a>
