// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  API: 'https://api.turistalocal.com',
  // API: 'https://turista-local.dev.axented.com',
  // API: 'http://localhost:3000',
  COOKIE: {
    DASHBOARD: 'dev-tl-dashboard-t',
    LANDING: 'dev-tl-landing-t',
  },
  FACEBOOKPROVIDER_ID: '237512731066282',
  // STRIPE_PUBLIC_KEY_TEST:
  //   'pk_test_51HSCuGFRquZmUVR67Dj6K5lAPAT0WZRsh9sFsd93VJW3U1oHvnqSLRGLzb9htQutKGBfADzrISqCaLZMONBPcmIv0008lBAi9Y',
  STRIPE_PUBLIC_KEY:
    'pk_live_51HSCuGFRquZmUVR6swtrQDElPs7BDvuHtkO7J8Kk8L1SQtxh6ogNWhQCdwwWRJVrf9Dej8bQpAQIJZuh9yNUD66300zMLeh4Ts',
  GOOGLEMAPS_API_KEY: 'AIzaSyAfduIhRuaEQBxNZvgHHYKDi8RJhnEjiDc',
}
