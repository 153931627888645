import { Component, OnInit, Input } from '@angular/core';
import { User } from '@models/user';

@Component({
  selector: 'app-user-profile-picture',
  templateUrl: './profile-picture.component.html'
})
export class ProfilePictureComponent implements OnInit {
  @Input() user: User = new User();

  constructor() {}

  ngOnInit() {}
}
