<ul *ngIf="activeTab == 'triplists'" class="items__wrapper">
  <li *ngFor="let triplist of triplists" class="item">
    <app-triplist-card
      [triplist]="triplist"
      (duplicateTriplist)="duplicatedCallback($event)"
      (deleteTriplist)="removeTriplist(triplist.id)">
    </app-triplist-card>
  </li>
</ul>
<ul *ngIf="activeTab == 'collections'" class="items__wrapper">
  <li *ngFor="let collection of collections">
    <app-collection-card [collection]="collection"></app-collection-card>
  </li>
</ul>
<div
  *ngIf="activeTab == 'triplists' && triplists.length == 0"
  class="empty__wrapper">
  <h4>
    {{ 'APP.PROFILE.EMPTY_TRIPLISTS' | translate }}
  </h4>
</div>
<div
  *ngIf="activeTab == 'collections' && collections.length == 0"
  class="empty__wrapper">
  <h4 *ngIf="activeTab == 'collections'">
    {{ 'APP.PROFILE.EMPTY_COLLECTIONS' | translate }}
  </h4>
</div>