import { Component, OnInit, Input } from '@angular/core';
import { Destination } from '@models/destination';

@Component({
  selector: 'app-destinations-carousel',
  templateUrl: './destinations-carousel.component.html'
})
export class DestinationsCarouselComponent implements OnInit {
  @Input() ctaQueryParams: object = {};
  @Input() ctaText: string;
  @Input() ctaUrl: string;
  @Input() customClass: string;
  @Input() headerTitle: string;
  @Input() headerSubtitle: string;
  @Input() isExternalUrl: boolean = false;
  @Input() items: Array<Destination> = [];
  @Input() outOfExplore: boolean = false;

  carouselCustomOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    nav: false,
    margin: 32,
    responsive: {
      0: {
        items: 1.2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      690: {
        items: 2
      },
      940: {
        items: 3
      }
    }
  }

  constructor() {}

  ngOnInit() {}
}
