<label [for]="id" [formGroup]="parentForm" [class]="customClass">
  <span class="input-name">{{ labelName }}</span>
  <span class="input__wrapper" [class.--error]="states.includes('--error')" [class.--warning]="states.includes('--warning')" [class.--success]="states.includes('--success')" [class.--filled]="states.includes('--filled')">
    <!-- Text input -->
    <input *ngIf="inputTypes.includes(type)" [type]="type" [readonly]="readonly" [formControlName]="id" [id]="id" (input)="verifyContent($event.target.value)" [placeholder]="placeholder">
    <!-- Number input -->
    <input *ngIf="type == 'number'" type="number" min="0" [formControlName]="id" [id]="id" [placeholder]="placeholder" digit-only>
    <!-- Textarea -->
    <textarea [rows]="rows || 4" *ngIf="type == 'textarea'" [formControlName]="id" [id]="id" (input)="verifyContent($event.target.value)" [placeholder]="placeholder">
    </textarea>
    <!-- Select -->
    <select *ngIf="type == 'select'" [formControlName]="id" [id]="id" (change)="onSelectChange.emit($event)">
      <option value="" disabled>{{ defaultOption }}</option>
      <option *ngFor="let option of collection" [ngValue]="option[collectionId]">
        {{ option[collectionName] }}
      </option>
    </select>
    <!-- Phone input -->
    <ngx-mat-intl-tel-input *ngIf="type == 'international-phone-number'" [onlyCountries]="['mx', 'us']" [enablePlaceholder]="true" name="phone" [formControlName]="id" (input)="verifyContent($event.target.value)">
    </ngx-mat-intl-tel-input>
    <!-- <international-phone-number *ngIf="type == 'international-phone-number'" [placeholder]="placeholder"
      [formControlName]="id" [maxlength]="20" [defaultCountry]="'mx'" [allowedCountries]="['mx', 'us']"
      (input)="verifyContent($event.target.value)">
    </international-phone-number> -->
    <!-- Date input -->
    <ng-container *ngIf="type == 'date'">
      <input type="text" [formControlName]="id" [id]="id" [placeholder]="placeholder || ('FORMS.MESSAGES.DATE_PLACEHOLDER' | translate)" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [min]="minDate" [max]="maxDate" (input)="verifyContent($event.target.value)" (dateTimeChange)="patchDate($event)">
      <owl-date-time #dt1 [pickerType]="'calendar'" [startView]="dateStartView">
      </owl-date-time>
    </ng-container>
    <!-- Switch -->
    <ui-switch *ngIf="type == 'switch'" [formControlName]="id" [id]="id" (change)="updateSwitchValue($event)">
    </ui-switch>
    <!-- Tag input -->
    <tag-input *ngIf="type == 'tag-input'" [formControlName]="id" [identifyBy]="tagIdentifier" [displayBy]="tagDisplay" [placeholder]="placeholder" [secondaryPlaceholder]="placeholder">
    </tag-input>
    <!-- Autocomplete input -->
    <ng-autocomplete *ngIf="type == 'autocomplete-input'" [formControlName]="id" [id]="id" [data]="collection" (change)="autocompleteChanged($event)" [searchKeyword]="collectionName || 'name'" [placeHolder]="placeholder" [notFoundText]="'FORMS.MESSAGES.NO_RESULTS' | translate" [itemTemplate]="autocompleteItemTemplate || defaultItemTemplate">
    </ng-autocomplete>
    <ng-template #defaultItemTemplate let-item *ngIf="type == 'autocomplete-input'">
      <a [innerHTML]="item.name"></a>
    </ng-template>
    <!-- Quill -->
    <quill-editor *ngIf="type == 'quill'" [formControlName]="id" [id]="id" (onContentChanged)="quillChanged($event)">
      <div quill-editor-toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <select class="ql-align">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-link"></button>
          <button class="ql-image"></button>
        </span>
      </div>
    </quill-editor>
    <!-- Image input -->
    <span *ngIf="type == 'image'" class="image-input__wrapper" (click)="imgUploader.click()" [ngStyle]="{ backgroundImage: 'url(' + image + ')' }" [ngClass]="image ? '--has-image' : ''">
      <div class="image screen">
        <div class="image-wrapper" [ngStyle]="{ backgroundImage: 'url(' + image + ')' }">
          <button type="button">
            <h4>
              <small>
                <b>
                  {{ 'FORMS.ACTIONS.CHANGE' | translate }}
                </b>
              </small>
            </h4>
          </button>
        </div>
      </div>
      <input type="hidden" [formControlName]="id">
      <input type="file" [id]="id" (change)="fileChanged($event)" #imgUploader hidden>
    </span>
  </span>
  <span class="input-helper" [class.--error]="states.includes('--error')" [class.--warning]="states.includes('--warning')" [class.--success]="states.includes('--success')">
    {{ currentError }}
  </span>
</label>
