
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';


import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomHttpInterceptor implements HttpInterceptor {
  currentRoute: string = window.location.pathname.split('/')[1];

  constructor(
    private cookies: CookieService,
    private translate: TranslateService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    let authReq = req.clone({
      headers: req.headers
        .append('Accept', 'application/vnd.turistalocal.v1+json')
    });

    // Set X-Platform header
    if (this.currentRoute === 'dashboard' || this.currentRoute === 'u') {
      authReq = authReq.clone({
        headers: authReq.headers
          .set('X-Platform', 'dashboard')
      });
    } else {
      authReq = authReq.clone({
        headers: authReq.headers
          .set('X-Platform', 'app')
      });
    }

    // Set Lang
    if (this.translate.currentLang !== undefined) {
      authReq = authReq.clone({
        headers: authReq.headers
          .append('Accept-Language', this.translate.currentLang)
      });
    }

    // Get User token
    if (this.currentRoute === 'dashboard') {
      authReq = authReq.clone({
        headers: authReq.headers
          .set('Authorization', this.cookies.get(environment.COOKIE.DASHBOARD) || '')
      });
    } else {
      const appToken = this.cookies.get(environment.COOKIE.LANDING);
      const guestToken = this.cookies.get('tl-guest');

      authReq = authReq.clone({
        headers: authReq.headers
          .set('Authorization', appToken || guestToken || '')
      });
    }

    // send the newly created request
    return next.handle(authReq).pipe(catchError((error, caught) => {
      if (error.status === 401) {
        this.router.navigate(['/']);
      }
      return observableThrowError(error);
    })) as any;
  }
}
