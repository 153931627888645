<ngx-smart-modal #deleteTriplistModal customClass="custom-modal" identifier="deleteTriplistModal" target="body">
  <div class="modal-header">
    <h4>
      {{ 'APP.TRIPLISTS.DELETE_TRIPLIST' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>
      {{ 'FORMS.MESSAGES.CONFIRM_DELETE' | translate }}
    </p>
  </div>
  <div class="modal-body d-flex justify-content-end --no-border">
    <button class="button--stroke" type="button" (click)="_modal.get('deleteTriplistModal').close()">
      {{ 'FORMS.ACTIONS.CANCEL' | translate }}
    </button>
    <button class="button--primary ml-3" type=" button" (click)="this.confirmDelete.emit(triplist)"
      [disabled]="isLoading">
      {{ 'FORMS.ACTIONS.CONFIRM_DELETE' | translate }}
    </button>
  </div>
</ngx-smart-modal>
