import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { Triplist } from '@models/triplist';

@Component({
  selector: 'app-delete-triplist',
  templateUrl: './delete-triplist.component.html',
})
export class DeleteTriplistComponent implements OnInit {
  @Input() isLoading: boolean

  @Output() confirmDelete: EventEmitter<object> = new EventEmitter()

  @ViewChild('deleteTriplistModal', { static: true }) modalElement: NgxSmartModalComponent;

  triplist: Triplist = new Triplist()

  constructor(public _modal: NgxSmartModalService) {}

  ngOnInit() {
    this.modalElement
      .onOpen
      .subscribe(() => {
        this.triplist = this.modalElement.getData().triplist
      })
  }
}
