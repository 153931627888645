import { NgModule, ModuleWithProviders } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { ToastrModule } from 'ngx-toastr'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { AgmCoreModule } from '@agm/core'
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number'
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input'
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime'
import { CarouselModule } from 'ngx-owl-carousel-o'
import { RouterModule } from '@angular/router'
import { AutocompleteLibModule } from 'angular-ng-autocomplete'
import { QuillModule } from 'ngx-quill'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { DigitOnlyDirective } from './directives/digit-only.directive'
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons'
import { ShareIconsModule } from 'ngx-sharebuttons/icons'
import { ClipboardModule } from 'ngx-clipboard'
import { TagInputModule } from 'ngx-chips'
import { NgxLoadersCssModule } from 'ngx-loaders-css'

import { Toastr } from './toastr/toastr.service'
import { InputComponent } from './components/inputs/input/input.component'
import { Capitalize } from './pipes/capitalize-first-letter'
import { DestinationsCarouselComponent } from './components/application/carousels/destinations-carousel/destinations-carousel.component'
import { PlacesCarouselComponent } from './components/application/carousels/places-carousel/places-carousel.component'
import { TableComponent } from './components/dashboard/table/table.component'
import { NgxSmartModalModule } from 'ngx-smart-modal'
import { UiSwitchModule } from 'ngx-toggle-switch'
import { InterestsFilterComponent } from './components/application/filters/interests-filter/interests-filter.component'
import { CreateCollectionModalComponent } from './components/application/modals/create-collection/create-collection.component'
import { DeleteCollectionComponent } from './components/application/modals/delete-collection/delete-collection.component'
import { PlaceCardComponent } from './components/application/cards/place-card/place-card.component'
import { DestinationCardComponent } from './components/application/cards/destination-card/destination-card.component'
import { NeighborhoodsCarouselComponent } from './components/application/carousels/neighborhoods-carousel/neighborhoods-carousel.component'
import { NeighborhoodCardComponent } from './components/application/cards/neighborhood-card/neighborhood-card.component'
import { TriplistsCarouselComponent } from './components/application/carousels/triplists-carousel/triplists-carousel.component'
import { TriplistCardComponent } from './components/application/cards/triplist-card/triplist-card.component'
import { UpcomingTriplistsCarouselComponent } from './components/application/carousels/upcoming-triplists-carousel/upcoming-triplists-carousel.component'
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive'
import { CollectionCardComponent } from './components/application/cards/collection-card/collection-card.component'
import { ProfileComponent } from './components/application/profile/profile/profile.component'
import { BasicInformationComponent } from './components/application/profile/basic-information/basic-information.component'
import { UserDataComponent } from './components/application/profile/user-data/user-data.component'
import { AsideComponent } from './components/application/profile/aside/aside.component'
import { ProfilePictureComponent } from './components/application/user/profile-picture/profile-picture.component'
import { LocalTouristCardComponent } from './components/application/cards/local-tourist-card/local-tourist-card.component'
import { LocalTouristsCarouselComponent } from './components/application/carousels/local-tourists-carousel/local-tourists-carousel.component'
import { ShareTriplistComponent } from '../pages/application/modals/share-triplist/share-triplist.component'
import { MyNotificationsComponent } from './components/application/user/my-notifications/my-notifications.component'
import { DateAgoPipe } from './pipes/date-ago'
import { DeleteTriplistComponent } from './components/application/modals/delete-triplist/delete-triplist.component'
import { CreatePlaceRecommendedByComponent } from './components/application/modals/create-place-recommended-by/create-place-recommended-by.component'
import { environment } from '@environments/environment'
import { BusinessProfileCardComponent } from './components/application/cards/business-profile-card/business-profile-card.component'
import { BusinessProfileComponent } from './components/application/business-profile/business-profile/business-profile.component'
import { BusinessProfileBasicInformationComponent } from './components/application/business-profile/business-profile-basic-information/business-profile-basic-information.component'
import { TableModule } from 'primeng/table'
import { TruncateTextPipe } from '../truncate-text.pipe'

@NgModule({
  declarations: [
    InputComponent,
    Capitalize,
    BusinessProfileBasicInformationComponent,
    BusinessProfileCardComponent,
    BusinessProfileComponent,
    DestinationsCarouselComponent,
    PlacesCarouselComponent,
    NeighborhoodsCarouselComponent,
    TriplistsCarouselComponent,
    TableComponent,
    UpcomingTriplistsCarouselComponent,
    InterestsFilterComponent,
    CreateCollectionModalComponent,
    CreatePlaceRecommendedByComponent,
    DeleteCollectionComponent,
    PlaceCardComponent,
    DestinationCardComponent,
    NeighborhoodCardComponent,
    TriplistCardComponent,
    ClickStopPropagationDirective,
    CollectionCardComponent,
    ProfileComponent,
    BasicInformationComponent,
    UserDataComponent,
    AsideComponent,
    ProfilePictureComponent,
    LocalTouristCardComponent,
    LocalTouristsCarouselComponent,
    DigitOnlyDirective,
    ShareTriplistComponent,
    MyNotificationsComponent,
    DateAgoPipe,
    DeleteTriplistComponent,
    TruncateTextPipe,
  ],
  imports: [
    RouterModule,
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLEMAPS_API_KEY,
    }),
    InternationalPhoneNumberModule,
    NgxMatIntlTelInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CarouselModule,
    TranslateModule,
    UiSwitchModule,
    AutocompleteLibModule,
    InfiniteScrollModule,
    ClipboardModule,
    TagInputModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxSmartModalModule.forRoot(),
    QuillModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
    }),
    NgxLoadersCssModule,
    TableModule,
  ],
  exports: [
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    AgmCoreModule,
    ToastrModule,
    TranslateModule,
    FormsModule,
    InputComponent,
    Capitalize,
    BusinessProfileBasicInformationComponent,
    BusinessProfileCardComponent,
    BusinessProfileComponent,
    DestinationsCarouselComponent,
    PlacesCarouselComponent,
    NeighborhoodsCarouselComponent,
    TableComponent,
    InterestsFilterComponent,
    CreateCollectionModalComponent,
    CreatePlaceRecommendedByComponent,
    DeleteCollectionComponent,
    PlaceCardComponent,
    DestinationCardComponent,
    NeighborhoodCardComponent,
    TriplistsCarouselComponent,
    TriplistCardComponent,
    UpcomingTriplistsCarouselComponent,
    CollectionCardComponent,
    ProfileComponent,
    ProfilePictureComponent,
    QuillModule,
    LocalTouristCardComponent,
    LocalTouristsCarouselComponent,
    InfiniteScrollModule,
    ShareButtonsModule,
    ClipboardModule,
    ShareTriplistComponent,
    ClickStopPropagationDirective,
    MyNotificationsComponent,
    NgxLoadersCssModule,
    TableModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [Toastr],
    }
  }
}
