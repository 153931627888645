interface AsideProfileInterface {
  collections: number,
  triplists: number,
  visited_neighborhoods: number
}

export class AsideProfile implements AsideProfileInterface {
  collections: number = 0;
  triplists: number = 0;
  visited_neighborhoods: number = 0;

  constructor(attrs: object = {}) {
    this.collections = attrs['collections'] || 0;
    this.triplists = attrs['triplists'] || 0;
    this.visited_neighborhoods = attrs['visited_neighborhoods']  || 0;
  }
}