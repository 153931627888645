<section id="upcoming-triplists-carousel" [class]="customClass">
  <div class="header">
    <div>
      <h4><small>{{ headerSubtitle }}</small></h4>
      <h4><small>{{ headerTitle }}</small></h4>
    </div>
    <a *ngIf="isExternalUrl" [href]="ctaUrl" target="_blank" class="button--link">
      {{ ctaText }}
    </a>
    <a *ngIf="!isExternalUrl" [routerLink]="ctaUrl" [queryParams]="ctaQueryParams" class="button--link">
      {{ ctaText }}
    </a>
  </div>
  <div class="carousel__wrapper">
    <button class="prev-button" type="button" (click)="owlCar.prev()">
      <img src="assets/images/icons/ic_chevron_left.svg" alt="<">
    </button>
    <button class="next-button" type="button" (click)="owlCar.next()">
      <img src="assets/images/icons/ic_chevron_right.svg" alt=">">
    </button>
    <owl-carousel-o class="upcoming-triplists-carousel" [options]="carouselCustomOptions" #owlCar>
      <ng-template carouselSlide *ngFor="let item of items">
        <app-triplist-card [triplist]="item" (deleteBookmarkCallback)="getTriplists.emit()"
          (duplicateTriplist)="getTriplists.emit()" (deleteTriplist)="getTriplists.emit()"
          (followUserCallback)="userFollowedCallback($event)" (unfollowUserCallback)="userUnfollowedCallback($event)">
        </app-triplist-card>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
