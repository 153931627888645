import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Destination } from '@models/destination';

@Component({
  selector: 'app-upcoming-triplists-carousel',
  templateUrl: './upcoming-triplists-carousel.component.html'
})
export class UpcomingTriplistsCarouselComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() headerSubtitle: string;
  @Input() ctaUrl: string;
  @Input() ctaText: string;
  @Input() ctaQueryParams: object = {};
  @Input() isExternalUrl: boolean = false;
  @Input() items: Array<Destination> = [];
  @Input() customClass: string;
  @Output() getNextTriplists: EventEmitter<object> = new EventEmitter();
  @Output() followUserCallback: EventEmitter<object> = new EventEmitter()
  @Output() unfollowUserCallback: EventEmitter<object> = new EventEmitter()

  carouselCustomOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    nav: false,
    margin: 32,
    responsive: {
      0: {
        items: 1.2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,

      },
      690: {
        items: 2
      },
      940: {
        items: 3
      }
    }
  }

  constructor() {}

  ngOnInit() {}

  userFollowedCallback(user) {
    this.followUserCallback.emit(user)
  }

  userUnfollowedCallback(user) {
    this.unfollowUserCallback.emit(user)
  }
}
