<a [routerLink]="['/local-tourists', localTourist.id]" class="local-tourist-card">
  <app-user-profile-picture [user]="localTourist"></app-user-profile-picture>
  <div class="local-tourist__info-wrapper">
    <h4>{{ localTourist.first_name }} {{ localTourist.last_name }}</h4>
    <p> {{ localTourist.local_tourist_profession[currentLanguage] }} </p>
    <h4>
      <small>
        {{ getLocation() }}
      </small>
    </h4>
  </div>
  <button *ngIf="followVisible && !localTourist.is_followed" type="button" class="button--stroke" (click)="follow()" click-stop-propagation>
    {{ 'APP.EXPLORE.LOCAL_TOURISTS.FOLLOW' | translate }}
  </button>
  <button *ngIf="followVisible && localTourist.is_followed" type="button" class="button--primary" (click)="unFollow()" click-stop-propagation>
    {{ 'APP.EXPLORE.LOCAL_TOURISTS.UNFOLLOW' | translate }}
  </button>
</a>
