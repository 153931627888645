import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@environments/environment';

import { UnauthGuard } from './shared/guards/unauth-guard.service';
import { AuthGuard } from './shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layouts/application/application.module').then(m => m.ApplicationModule)
  },
  {
    path: '',
    loadChildren: () => import('./layouts/clean/clean.module').then(m => m.CleanModule)
  },
  {
    path: 'u',
    loadChildren: () => import('./layouts/unauth/unauth.module').then(m => m.UnauthModule),
    canActivate: [UnauthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./layouts/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    data: { tokenName: environment.COOKIE.DASHBOARD }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
