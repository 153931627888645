import { Component, OnInit, Input } from '@angular/core'
import { User } from '@models/user'
import { Toastr } from '@shared/toastr/toastr.service'
import { UsersService } from '@shared/services/users.service'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { Subscription } from 'rxjs'
import { ApplicationService } from '../../../../services/application.service'

@Component({
  selector: 'app-local-tourist-card',
  templateUrl: './local-tourist-card.component.html',
})
export class LocalTouristCardComponent implements OnInit {
  @Input() localTourist: User = new User()
  @Input() followVisible: boolean = true
  currentLanguage: string
  currentUser: User
  subscriptions: Subscription = new Subscription()

  constructor(
    private _user: UsersService,
    private applicationService: ApplicationService,
    private modal: NgxSmartModalService,
    private toastr: Toastr,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.applicationService.currentLanguage.subscribe((language) => {
        this.currentLanguage = language
      }),
    )

    this.subscriptions.add(
      this._user.current.subscribe((user) => {
        this.currentUser = user
      }),
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  getLocation() {
    const destination = this.localTourist.destination
    const country = this.localTourist.country
    const cityName = destination ? destination.name : this.localTourist.hometown
    const countryName = country ? country.name : null
    let ary = []

    if (cityName) {
      ary.push(cityName)
    }
    if (countryName) {
      ary.push(countryName)
    }

    return ary.join(', ')
  }

  follow() {
    if (this.currentUser.id !== '') {
      const body = { following_id: this.localTourist.id }

      this._user.followUser(body).subscribe(
        (response) => {
          this.localTourist.is_followed = true
        },
        (errors) => {
          this.toastr.error(errors)
        },
      )
    } else {
      this.modal.get('signUpModal').open()
    }
  }

  unFollow() {
    const user = this.localTourist.id

    this._user.unfollowUser(user).subscribe(
      (response) => {
        this.localTourist.is_followed = false
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }
}
