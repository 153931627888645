import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Toastr {
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  success(message?: string, title?: string) {
    this.toastr.success(message, title);
  }

  error(errorMessages) {
    if (errorMessages.status === 500) {
      const message = this.translate.instant('COMMON.ERRORS.500');

      this.single_error(message);
    } else if (errorMessages.status === 404) {
      this.toastr.error(errorMessages);
    } else if (errorMessages.status === 0) {
      this.toastr.error(errorMessages);
    } else {
      let messageArray: Array<string>;
      messageArray = errorMessages.error.errors;
      messageArray.forEach(error => {
        this.toastr.error(error);
      });
    }
  }

  single_error(message) {
    this.toastr.error(message);
  }
}
