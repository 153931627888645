<div class="container">
  <div class="row interest-filter__wrapper">
    <div class="col-12">
      <div class="filters__wrapper">
        <h4>
          <small>
            {{ 'MODELS.INTEREST.OTHER' | translate }}
          </small>
        </h4>
        <button
          type="button"
          (click)="reset()"
          class="button--stroke --small"
          [class.--is-active]="filteredInterest == ''">
          {{ 'COMMON.ALL' | translate | capitalize }}
        </button>
        <button
          *ngFor="let interest of interests"
          type="button"
          class="button--stroke --small"
          (click)="setFilter(interest.id)"
          [class.--is-active]="filteredInterest == interest.id">
          {{ interest.name[language] }}
        </button>
      </div>
    </div>
  </div>
</div>