import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Interest } from 'src/app/shared/models/interest';
import { InterestsService } from 'src/app/pages/dashboard/interests/interests.service';
import { CookieService } from 'ngx-cookie';
import { Toastr } from 'src/app/shared/toastr/toastr.service';

@Component({
  selector: 'app-interests-filter',
  templateUrl: './interests-filter.component.html'
})
export class InterestsFilterComponent implements OnInit {
  @Input() interests: Array<Interest> = [];
  @Output() resetInterestFilter: EventEmitter<object> = new EventEmitter();
  @Output() setInterestFilter: EventEmitter<string> = new EventEmitter();

  language: string = this.cookies.get('tl-lang') || 'en';
  filteredInterest: string = '';

  constructor(
    private _interests: InterestsService,
    private cookies: CookieService,
    private toastr: Toastr
  ) {}

  ngOnInit() {
    if (this.interests.length == 0) {
      this.getInterests();
    }
  }

  reset() {
    this.filteredInterest = '';
    this.resetInterestFilter.emit();
  }

  setFilter(id: string) {
    this.filteredInterest = id;
    this.setInterestFilter.emit(id);
  }

  getInterests() {
    this._interests.getAllInterests().subscribe(response => {
      this.interests = response['interests'];
    }, errors => {
      this.toastr.error(errors);
    });
  }
}
