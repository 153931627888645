<a [routerLink]="setUrl()" class="destination-card">
  <div class="screen">
    <div class="image-wrapper" [ngStyle]="{ backgroundImage: 'url(' + destination.cover_picture_url + ')' }">
    </div>
  </div>
  <h4>{{ destination.name }}</h4>
  <p>
    {{ (destination.description[language].length>6)? (destination.description[language] | slice:0:105)+'...':(destination.description[language]) }}
  </p>
</a>
