export class Pagination {
  currentPage: number = 1
  nextPage: any = null
  prevPage: any = null
  totalCount: number = 0
  total_count: number
  totalPages: number = 1
  total_pages: number
  isLoading: boolean

  private _ELEMENTES_PER_PAGE: number = 15

  get pageInitElementNumber(): number {
    const virtualInitElementNumber = this._ELEMENTES_PER_PAGE + 1

    return this.currentPage === 1 ? 1 : virtualInitElementNumber
  }

  get pageEndElementNumber(): number {
    const virtualEndElementNumber = this.currentPage * this._ELEMENTES_PER_PAGE

    return virtualEndElementNumber > this.totalCount
      ? this.totalCount
      : virtualEndElementNumber
  }

  constructor(pagination: any = {}) {
    this.currentPage = pagination.current_page || 1
    this.nextPage = pagination.next_page
    this.prevPage = pagination.prev_page || null
    this.totalCount = pagination.total_count || 0
    this.total_count = pagination.total_count
    this.totalPages = pagination.total_pages || 1
    this.total_pages = pagination.total_pages
    this.isLoading = false
  }
}
