<ngx-smart-modal #deleteCollectionModal customClass="custom-modal" identifier="deleteCollectionModal" target="body">
  <div class="modal-header">
    <h4>{{ 'APP.COLLECTIONS.DELETE_COLLECTION' | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>
      {{ 'FORMS.MESSAGES.CONFIRM_DELETE' | translate }}
    </p>
  </div>
  <div class="modal-body d-flex justify-content-end --no-border">
    <button class="button--stroke" type="button" (click)="_modal.get('deleteCollectionModal').close()">
      {{ 'FORMS.ACTIONS.CANCEL' | translate }}
    </button>
    <button class="button--primary ml-3" type=" button" (click)="this.confirmDelete.emit()" [disabled]="isLoading">
      {{ 'FORMS.ACTIONS.CONFIRM_DELETE' | translate }}
    </button>
  </div>
</ngx-smart-modal>
