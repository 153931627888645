import { Component, OnInit, Input } from '@angular/core';
import { User } from '@models/user';

@Component({
  selector: 'app-local-tourists-carousel',
  templateUrl: './local-tourists-carousel.component.html'
})
export class LocalTouristsCarouselComponent implements OnInit {
  @Input() ctaQueryParams: object = {};
  @Input() ctaText: string;
  @Input() ctaUrl: string;
  @Input() customClass: string;
  @Input() headerTitle: string;
  @Input() headerSubtitle: string;
  @Input() isExternalUrl: boolean = false;
  @Input() items: Array<User> = [];

  carouselCustomOptions: object = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    nav: false,
    margin: 32,
    responsive: {
      0: {
        items: 1.5,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      690: {
        items: 2
      },
      940: {
        items: 4
      }
    }
  };

  constructor() {}

  ngOnInit() {}
}
