import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import { BehaviorSubject, Subject } from 'rxjs'
import { Triplist } from '@shared/models/triplist'
import { Activity } from '@models/activity'

@Injectable({
  providedIn: 'root',
})
export class TriplistsService {
  constructor(private httpClient: HttpClient) {}

  private activitiesUpdatedSource = new BehaviorSubject<Activity[]>([])
  private triplistUpdatedSource = new BehaviorSubject<Triplist>(new Triplist())
  private triplistEditSource = new BehaviorSubject<boolean>(false)

  acivitiesUpdated = this.activitiesUpdatedSource.asObservable()
  public current = this.triplistUpdatedSource.asObservable()
  public isEdit = this.triplistEditSource.asObservable()

  activitiesUpdate(activities: Activity[]) {
    this.activitiesUpdatedSource.next(activities)
  }

  triplistUpdated(triplist: Triplist) {
    this.triplistUpdatedSource.next(triplist)
  }

  triplistIsEdit(value: boolean) {
    this.triplistEditSource.next(value)
  }

  createTriplist(payload: object) {
    return this.httpClient.post(`${environment.API}/triplists`, {
      triplist: payload,
    })
  }

  updateTriplist(id: string, payload: {}) {
    return this.httpClient.put(`${environment.API}/triplists/${id}`, payload)
  }

  getGuestTriplist(triplistId: string) {
    return this.httpClient.get(
      `${environment.API}/guest_triplists/${triplistId}`,
    )
  }

  getTriplist(id: string) {
    return this.httpClient.get(`${environment.API}/triplists/${id}`)
  }

  getTriplistPdf(id: string) {
    return this.httpClient.get(`${environment.API}/triplists_pdf/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  getSuggestedPlaces(page: string) {
    const params = { page: page }

    return this.httpClient.get(`${environment.API}/suggested_places`, {
      params: params,
    })
  }

  inviteGuests(id: string, value: object) {
    const body = { triplist: value }

    return this.httpClient.post(
      `${environment.API}/triplists/${id}/guests`,
      body,
    )
  }

  getGuest(id: string) {
    return this.httpClient.get(`${environment.API}/triplists/${id}/guest`)
  }
}
