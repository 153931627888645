import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { Collection } from '@models/collection'
import { TranslateService } from '@ngx-translate/core'
import { CollectionsService } from '@shared/services/collections.service'
import { Toastr } from '@shared/toastr/toastr.service'
import { CookieService } from 'ngx-cookie'

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
})
export class CollectionCardComponent implements OnInit {
  @Input() collection: Collection = new Collection()
  @Output() getCollections: EventEmitter<object> = new EventEmitter()
  @Output() editCollection: EventEmitter<object> = new EventEmitter()
  @Output() deleteCollection: EventEmitter<object> = new EventEmitter()

  language: string = this.cookieService.get('tl-lang') || 'en'
  whatsappMsg: string

  constructor(
    private _collections: CollectionsService,
    private cookieService: CookieService,
    private toastr: Toastr,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {}

  fbUrl() {
    return `http://www.facebook.com/dialog/send?app_id=664848067549895&link=${window.location.href}&redirect_uri=${window.location.href}`
  }

  copiedToClipboard() {
    let message = this.translateService.instant('COMMON.COPIED')

    this.toastr.success(message)
  }

  getUrl() {
    const origin = window.location.origin

    return origin + '/collections/' + this.collection.id
  }

  mailUrl() {
    return `mailto:?subject=&body=${window.location.href}`
  }

  whatsappUrl() {
    if (this.language === 'en') {
      this.whatsappMsg = 'I want to share this collection with you...'
    } else if (this.language === 'es-MX') {
      this.whatsappMsg = 'Quiero compartir esta colección contigo...'
    }
    return `https://web.whatsapp.com/send?text=${this.whatsappMsg}${window.location.href}`
  }
}
