import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'

@Injectable({
  providedIn: 'root',
})
export class TriplistsService {
  constructor(private httpClient: HttpClient) {}

  getAllTriplists(q_adv: string) {
    const params = { q_adv }

    return this.httpClient.get(`${environment.API}/triplists`, { params })
  }

  getLatestsTriplists() {
    return this.httpClient.get(`${environment.API}/triplists`)
  }

  getLatestTriplistsForUser() {
    return this.httpClient.get(`${environment.API}/latest_triplists`)
  }

  deleteTriplist(id: string) {
    return this.httpClient.delete(`${environment.API}/triplists/${id}`)
  }

  like(id: string) {
    const body = { triplist_like: { triplist_id: id } }

    return this.httpClient.post(`${environment.API}/triplist_likes`, body)
  }

  dislike(id: string) {
    return this.httpClient.delete(`${environment.API}/triplist_likes/${id}`)
  }

  duplicate(id: string) {
    return this.httpClient.post(`${environment.API}/triplists/${id}/clone`, {})
  }
}
