<section class="place-card">
  <div class="screen">
    <div class="options__wrapper">
      <div ngbDropdown container="body">
        <button class="btn btn-outline-primary mr-2" id="dropdownBasic1" ngbDropdownToggle>
          <img src="/assets/images/icons/ic_share.svg" alt="">
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a [href]="whatsappUrl()" target="_blank">
            <button ngbDropdownItem>
              <img width="30px" src="/assets/images/common/logo_whatsapp.png" alt="">
              WhatsApp
            </button>
          </a>
          <button ngbDropdownItem class="py-0 mt-2 d-sm-block d-lg-none" shareButton="messenger">
            <img width="30px" class="imgSharing" src="/assets/images/common/logo_messenger.png" alt="">
            Messenger
          </button>
          <a [href]="fbUrl()" target="_blank" class="d-none d-lg-block">
            <button ngbDropdownItem class="py-0 mt-2">
              <img width="30px" src="/assets/images/common/logo_messenger.png" alt="">
              Messenger
            </button>
          </a>
          <a [href]="mailUrl()" target='_blank'>
            <button ngbDropdownItem class="py-0">
              <img width="30px" class="imgSharing" src="/assets/images/icons/ic_mail_black.svg" alt="">
              {{ 'MODELS.USER.EMAIL' | translate | capitalize }}
            </button>
          </a>
          <button ngbDropdownItem class="py-0" ngxClipboard [cbContent]="getUrl()" (cbOnSuccess)="copiedToClipboard()">
            <img width="30px" class="imgSharing" src="/assets/images/icons/ic_link_black.svg" alt="">
            {{ 'APP.EXPLORE.COPY_LINK' | translate }}
          </button>
        </div>
      </div>
      <button style="z-index: -1;" type="button" (click)="handleBookmark()" click-stop-propagation [class.--active]="bookmarked" placement="top" ngbTooltip="{{'APP.EXPLORE.SAVE_TO_BOOKMARKS' | translate}}" [openDelay]="500">
        <div class="icon__wrapper">
          <img src="/assets/images/icons/ic_bookmark_outline.svg" alt="">
          <img src="/assets/images/icons/ic_bookmark.svg" alt="">
        </div>
      </button>
    </div>
    <a [routerLink]="setUrl()" class="place-card">
      <div class="image" [ngStyle]="{ backgroundImage: 'url(' + place.cover_picture_url + ')' }">
      </div>
    </a>
  </div>
  <a [routerLink]="setUrl()" class="place-card">
    <div class="tags__wrapper">
      <h4 *ngFor="let interest of place.interests.slice(0, 2)">
        <small>{{ interest['name'][language] }}</small>
      </h4>
      <h4 *ngIf="place.interests.length > 2">
        <small>{{ place.interests.length - 2 }}+</small>
      </h4>
    </div>
    <h4 class="title">{{ place.name }}</h4>
    <h4 class="location">
      <small>{{ place.destination_name }}, {{ place.country_name }}</small>
    </h4>
    <p> {{ updatedDescription | truncateText:"110" }}</p>
  </a>
</section>
