import { Day } from "./day";
import { User } from "./user";
import { Bookmark } from "./bookmark";
import { Guest } from "./guest";

interface TriplistInterface {
  id: string;
  bookmark: Bookmark;
  days: Array<Day>;
  end_date: string;
  guests: Array<Guest>;
  is_collaborative: boolean;
  likes_count: number;
  name: string;
  picture: string;
  picture_url: string;
  start_date: string;
  user: User;
  user_liked: boolean;
}

export class Triplist implements TriplistInterface {
  // TODO (Raúl Amador): Fix attribute names to camel case. Check all uses.
  id: string;
  bookmark: Bookmark;
  days: Array<Day> = [];
  end_date: string;
  guests: Array<Guest> = [];
  is_collaborative: boolean;
  likes_count: number;
  name: string;
  picture: string;
  picture_url: string;
  start_date: string;
  user: User;
  user_liked: boolean;

  constructor(attrs: object = {}) {
    this.id = attrs['id'] || '';
    this.bookmark = attrs['bookmark'] || null;
    this.days = attrs['days'] || [];
    this.end_date = attrs['end_date'] || '';
    this.guests = this.setGuests(attrs['guests'] || []);
    this.is_collaborative = attrs['is_collaborative'] || false;
    this.likes_count = attrs['likes_count'] || 0;
    this.name = attrs['name'] || '';
    this.picture = attrs['picture'] || '';
    this.picture_url = attrs['picture_url'] || '';
    this.start_date = attrs['start_date'] || '';
    this.user = attrs['user'] || new User();
    this.user_liked = attrs['user_liked'] || false;
  }

  setGuests(guests: Array<Guest>) {
    return guests.map(guest => new Guest(guest));
  }
}
