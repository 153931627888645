import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Place } from 'src/app/shared/models/place';

@Component({
  selector: 'app-places-carousel',
  templateUrl: './places-carousel.component.html'
})
export class PlacesCarouselComponent implements OnInit {
  @Input() ctaUrl: string;
  @Input() ctaText: string;
  @Input() customClass: string = '';
  @Output() getPlaces: EventEmitter<object> = new EventEmitter();
  @Input() headerTitle: string;
  @Input() headerSubtitle: string;
  @Input() isExternalUrl: boolean = false;
  @Input() items: Array<Place> = [];
  @Input() ctaQueryParams: object = {};

  carouselCustomOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    nav: false,
    margin: 32,
    responsive: {
      0: {
        items: 1.2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      690: {
        items: 2
      },
      940: {
        items: 5
      }
    }
  }

  constructor() {}

  ngOnInit() {}
}
