<section id="application__user__profile">
  <section id="application__user__profile__basic-information">
    <app-basic-information [user]="user"></app-basic-information>
  </section>
  <section id="application__user__profile__user-data">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="tabs__wrapper">
            <li>
              <button
                type="button"
                (click)="toggleTab('triplists')"
                class="button--secondary"
                [class.--is-active]="activeTab == 'triplists'">
                {{ 'MODELS.TRIPLIST.OTHER' | translate }}
              </button>
            </li>
            <li>
              <button
                type="button"
                (click)="toggleTab('collections')"
                class="button--secondary"
                [class.--is-active]="activeTab == 'collections'">
                {{ 'MODELS.COLLECTION.OTHER' | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="user-data__wrapper col-md-8">
          <app-user-data
            [activeTab]="activeTab"
            [collections]="collections"
            [triplists]="triplists">
          </app-user-data>
        </div>
        <aside class="col-md-4">
          <app-user-aside [user]="user"></app-user-aside>
        </aside>
      </div>
    </div>
  </section>
</section>