<div
  *ngIf="user.profile_picture_url != null"
  class="user-profile-picture"
  [ngStyle]="{ background: 'url(' + user.profile_picture_url + ')'}">
</div>
<div *ngIf="user.profile_picture_url == null" class="user-profile-picture">
  <h4 class="placeholder" *ngIf="user.first_name && user.last_name">
    {{ user.first_name.substring(0,1) | uppercase }}{{ user.last_name.substring(0,1) | uppercase}}
  </h4>
</div>
