import { Component, OnInit, Input } from '@angular/core'
import { CookieService } from 'ngx-cookie'
import { Neighborhood } from '@models/neighborhood'
import { Subscription } from 'rxjs'
import { ApplicationService } from '../../../../services/application.service'

@Component({
  selector: 'app-neighborhood-card',
  templateUrl: './neighborhood-card.component.html',
})
export class NeighborhoodCardComponent implements OnInit {
  @Input() neighborhood: Neighborhood = new Neighborhood()
  @Input() routeId: boolean = false

  language: string = this.cookies.get('tl-lang') || 'en'
  subscriptions: Subscription = new Subscription()
  textUpdated: any
  updatedDescription: string

  constructor(
    private applicationService: ApplicationService,
    private cookies: CookieService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.applicationService.currentLanguage.subscribe((language) => {
        this.updatedDescription = this.neighborhood.description[language]
      }),
    )
  }

  onDesytroy() {
    this.subscriptions.unsubscribe()
  }

  setUrl() {
    const id = this.neighborhood.id

    return this.routeId ? [id] : ['neighborhoods', id]
  }
}
