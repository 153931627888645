import { Place } from "./place";

interface CollectionInterface {
  id: string;
  name: string;
  picture_url: string;
  places: Array<Place>;
}

export class Collection implements CollectionInterface {
  id: string = '';
  name: string = '';
  picture_url: string = '';
  places: Array<Place> = [];

  constructor(attrs = {id: '', name: '', picture_url: '', places: []}) {
    this.id = attrs.id
    this.name = attrs.name
    this.picture_url = attrs.picture_url
    this.places = attrs.places
  }

  hasPlace(place) {
    return this.places.map(place => place.id).includes(place.id)
  }
}