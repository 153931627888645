import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  language: string = this.cookies.get('tl-lang') || 'en';

  constructor(private cookies: CookieService) {}

  private languageUpdatedSource = new BehaviorSubject<string>(this.language);
  private menuUpdatedSource = new BehaviorSubject<boolean>(false);

  public currentLanguage = this.languageUpdatedSource.asObservable();
  public isMenuOpen = this.menuUpdatedSource.asObservable();

  languageUpdated(language: string) {
    this.languageUpdatedSource.next(language);
  }

  menuOpenUpdated(state: boolean) {
    const body = document.getElementsByTagName('body')[0];
    this.menuUpdatedSource.next(state);

    if (state) {
      body.classList.add('--mobile-no-scroll');
    } else {
      body.classList.remove('--mobile-no-scroll');
    }
  }
}
