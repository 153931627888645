// HACK (Raúl Amador): userUpdatedSource should be null for validations
//                     (check all dependencies of this subject)

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import { User } from '../models/user'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  private userUpdatedSource = new BehaviorSubject<User>(new User())

  public current = this.userUpdatedSource.asObservable()

  userUpdated(user: User) {
    this.userUpdatedSource.next(user)
  }

  getAllUsers(
    page: string = '1',
    sortField: string = 'first_name',
    sortOrder: string = '',
  ) {
    const params = { page, sortField, sortOrder }

    return this.httpClient.get(`${environment.API}/users`, { params: params })
  }

  getUserById(id: string) {
    return this.httpClient.get(`${environment.API}/users/${id}`)
  }

  getAdminUsers(params: any) {
    return this.httpClient.get(`${environment.API}/users`, { params: params })
  }

  createAdmin(payload: any) {
    return this.httpClient.post(`${environment.API}/users`, { user: payload })
  }

  updateAdmin(id: string, body: any) {
    return this.httpClient.put(`${environment.API}/users/${id}`, { user: body })
  }

  updateCurrentAdmin(payload: any) {
    return this.httpClient.put(`${environment.API}/settings`, { user: payload })
  }

  getProfile() {
    return this.httpClient.get(`${environment.API}/profile`)
  }

  confirmEmail(token: string) {
    return this.httpClient.post(`${environment.API}/confirm_email`, {
      token: token,
    })
  }

  updateUser(value: object) {
    return this.httpClient.put(`${environment.API}/profile`, value)
  }

  updateUserDashboard(userId: string, value) {
    return this.httpClient.put(`${environment.API}/users/${userId}`, value)
  }

  updateInterests(interests: Array<object>) {
    const body = { user: { interests: interests } }

    return this.httpClient.put(`${environment.API}/interests`, body)
  }

  updatePassword(value: object) {
    return this.httpClient.put(`${environment.API}/update_password`, {
      user: value,
    })
  }

  deleteSocialNetwork(id: string) {
    return this.httpClient.delete(`${environment.API}/social_networks/${id}`)
  }

  updateDestinations(destinations: Array<object>) {
    const body = { user: { destinations: destinations } }

    return this.httpClient.put(`${environment.API}/destinations`, body)
  }

  followUser(body: object) {
    return this.httpClient.post(`${environment.API}/followers`, {
      follower: body,
    })
  }

  unfollowUser(id: string) {
    return this.httpClient.delete(`${environment.API}/followers/${id}`)
  }
}
