<div class="card index-card">
  <div class="card-header">
    <div class="info__wrapper">
      <div class="card-title__wrapper">
        <h4>{{ title }}</h4>
      </div>
      <p *ngIf="items">
        <small>
          {{ items.length }}
          {{ (items.length > 1 ? 'COMMON.ITEMS' : 'COMMON.ITEMS') | translate }}
        </small>
      </p>
    </div>
    <a *ngIf="hasObservers('create')" (click)="emitCreate()">
      <img
        src="assets/images/icons/ic_add_circle.svg"
        [alt]="'DASHBOARD.COMMON.ADD' | translate">
      {{ 'DASHBOARD.COMMON.ADD' | translate }}
    </a>
    <a *ngIf="createUrl" (click)="createRedirect()">
      <img
        src="assets/images/icons/ic_add_circle.svg"
        [alt]="'DASHBOARD.COMMON.ADD' | translate">
      {{ 'DASHBOARD.COMMON.ADD' | translate }}
    </a>
  </div>
  <div class="card-body">
    <table class="table table-hover">
      <thead>
        <tr>
          <ng-container *ngFor="let column of options['columns']">
            <td>{{ column['header'] }}</td>
          </ng-container>
          <td *ngIf="hasControls()" class="controls">
            {{ 'DASHBOARD.COMMON.CONTROLS' | translate }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of items"
          [class]="clickRedirect ? '--clickable' : ''"
          (click)="handleRedirect(item)">
          <ng-container *ngFor="let column of options['columns']">
            <td
              *ngIf="column['type'] == 'text'"
              [class]="column['customClass']">
              {{ getText(item, column) || '-' }}
            </td>
            <td
              *ngIf="column['type'] == 'count'"
              [class]="column['customClass']">
              {{ (item[column['key']].length | number) || '-' }}
            </td>
            <td
              *ngIf="column['type'] == 'boolean'"
              [class]="(column['customClass'] ? column['customClass'] : '') + ' --is-boolean --' + getBooleanValue(item, column)">
              <span>
                {{ ('DASHBOARD.COMMON.' + (getBooleanValue(item, column)) | uppercase ) | translate }}
              </span>
            </td>
          </ng-container>
          <td *ngIf="hasControls()" class="controls">
            <button
              *ngIf="hasObservers('edit')"
              (click)="emitEdit(item)">
              <img
                src="assets/images/icons/ic_edit.svg"
                [alt]="'DASHBOARD.COMMON.EDIT' | translate">
            </button>
            <button
              *ngIf="hasObservers('destroy')"
              (click)="emitDestroy(item)">
              <img
                src="assets/images/icons/ic_trash.svg"
                [alt]="'DASHBOARD.COMMON.DESTROY' | translate">
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>