import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { User } from '@models/user'
import { Toastr } from '@shared/toastr/toastr.service'
import { UsersService } from '@shared/services/users.service'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-business-profile-card',
  templateUrl: './business-profile-card.component.html',
})
export class BusinessProfileCardComponent implements OnInit, OnDestroy {
  @Input() businessProfile: User = new User()
  @Input() followVisible: boolean = true
  currentUser: User
  subscriptions: Subscription = new Subscription()

  constructor(
    private _user: UsersService,
    private modal: NgxSmartModalService,
    private toastr: Toastr,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this._user.current.subscribe((user) => {
        this.currentUser = user
      }),
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  getLocation() {
    const destination = this.businessProfile.destination
    const country = this.businessProfile.country
    const cityName = destination
      ? destination.name
      : this.businessProfile.hometown
    const countryName = country ? country.name : null
    let ary = []

    if (cityName) {
      ary.push(cityName)
    }
    if (countryName) {
      ary.push(countryName)
    }

    return ary.join(', ')
  }

  follow() {
    if (this.currentUser.id !== '') {
      const body = { following_id: this.businessProfile.id }

      this._user.followUser(body).subscribe(
        (response) => {
          this.businessProfile.is_followed = true
        },
        (errors) => {
          this.toastr.error(errors)
        },
      )
    } else {
      this.modal.get('signUpModal').open()
    }
  }

  unFollow() {
    const user = this.businessProfile.id

    this._user.unfollowUser(user).subscribe(
      (response) => {
        this.businessProfile.is_followed = false
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }
}
