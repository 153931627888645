// TODO: Document this component for future usage.
// TODO: Add warning and success messages as required.
// NOTE: Based on this Medium post:
//       https://medium.com/@joshblf/using-child-components-in-angular-forms-d44e60036664

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {
  @Input() autocompleteItemTemplate: any
  @Input() collection: Array<object> = []
  @Input() collectionId: string = 'id'
  @Input() collectionName: string = 'name'
  @Input() customClass: string = ''
  @Input() customError: string
  @Input() dateStartView: string = 'year'
  @Input() defaultOption: string = this.translate.instant(
    'FORMS.MESSAGES.DEFAULT_OPTION',
  )
  @Input() id: any
  @Input() image: string
  @Input() labelName: string
  @Input() readonly: boolean
  @Input() minDate: Date
  @Input() maxDate: Date
  @Input() parentForm: FormGroup
  @Input() placeholder: string = ''
  @Input() rows: string
  @Input() identifyBy: string = 'value'
  @Input() displayBy: string = 'display'
  @Input() type: string = 'text'
  @Input() tagIdentifier = 'value'
  @Input() tagDisplay = 'display'

  @Output() autocompleteChanged: EventEmitter<object> = new EventEmitter()
  @Output() onSelectChange: EventEmitter<object> = new EventEmitter()
  @Output() onDateChange: EventEmitter<object> = new EventEmitter()
  @Output() onImageChange: EventEmitter<string> = new EventEmitter()

  states: Array<string> = []
  currentError: string
  control: FormControl
  inputTypes: Array<string> = ['text', 'email', 'password']

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {}

  verifyContent(value: string) {
    this.verifyValue(value)
    this.checkForErrors()
  }

  verifyValue(value: string) {
    if (value != '' && value != null) {
      this.addState('--filled')
    } else {
      this.removeState('--filled')
    }
  }

  triggerError() {
    this.checkForErrors()
  }

  checkForErrors() {
    const control = this.parentForm.controls[this.id]

    if (control.invalid) {
      this.removeState('--error', '--warning', '--success')

      Object.keys(control.errors).forEach((error) => {
        switch (error) {
          case 'required': {
            this.addState('--error')
            this.currentError = this.translate.instant(
              'FORMS.MESSAGES.IS_REQUIRED',
            )
            break
          }
          case 'pattern': {
            this.addState('--warning')
            const invalidPattern = this.translate.instant(
              'FORMS.MESSAGES.INVALID_PATTERN',
            )

            this.currentError = this.customError || invalidPattern
            break
          }
          case 'phoneEmptyError': {
            this.addState('--warning')
            this.currentError = this.translate.instant(
              'FORMS.MESSAGES.INVALID_PHONE',
            )
            break
          }
          default: {
            this.addState('--error')
            this.currentError = this.translate.instant(
              'FORMS.MESSAGES.COMMON_ERROR',
            )
            break
          }
        }
      })
    } else {
      this.addState('--success')
      this.currentError = null
    }
  }

  addState(...states: Array<string>) {
    states.forEach((state) => {
      if (!this.states.includes(state)) {
        this.states.push(state)
      }
    })
  }

  removeState(...states: Array<string>) {
    states.forEach((state) => {
      this.states = this.states.filter((value) => value !== state)
    })
  }

  patchDate(event: any) {
    const dateObject = {}
    dateObject[this.id] = event.value.toISOString()

    this.parentForm.patchValue(dateObject)
    this.onDateChange.emit(event)
  }

  updateSwitchValue(active: boolean) {
    const value = {}
    value[this.id] = active

    this.parentForm.patchValue(value)
  }

  // This gets an undefined error if no picture is saved before
  fileChanged(event) {
    if (!event) {
      event = window.event
    }
    const evt = event.target || event.srcElement
    const file = evt.files

    if (file && file[0]) {
      const fileSize = file[0].size / 1024 / 1024

      if (fileSize <= 1) {
        const value = {}
        const reader = new FileReader()
        value[this.id] = file[0]

        reader.onload = (e: any) => {
          this.image = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.parentForm.patchValue(value)
        this.onImageChange.emit(this.image)
      } else {
        const errorMsg = this.translate.instant(
          'APP.TRIPLISTS.TRIPLIST.SIZE_LIMIT_ERROR',
        )
        this.toastr.error(errorMsg)
      }
    }
  }

  quillChanged(event: any) {
    this.verifyContent(event.text)
  }
}
