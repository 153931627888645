<a [routerLink]="['/triplists', triplist.id]" class="triplist-card">
  <div class="screen">
    <div *ngIf="showAuthor && !isOwner" class="author__wrapper">
      <app-user-profile-picture [user]="triplist.user"></app-user-profile-picture>
      <div class="data">
        <p>{{ triplist.user.first_name }} {{ triplist.user.last_name }}</p>
        <h4>
          <small>
            {{ triplist.user.hometown }}, {{ triplist.user.country.name }}
          </small>
        </h4>
        <button *ngIf="!isOwner && !triplist.user.is_followed" type="button" (click)="followLocalTourist(triplist.user)"
          click-stop-propagation>
          <h4>
            <small>
              {{ 'MODELS.LOCAL_TOURIST.FOLLOW' | translate | uppercase }}
            </small>
          </h4>
        </button>
        <button *ngIf="!isOwner && triplist.user.is_followed" type="button" (click)="unFollowLocalTouris(triplist.user)"
          click-stop-propagation>
          <h4>
            <small>
              {{ 'MODELS.LOCAL_TOURIST.UNFOLLOW' | translate | uppercase }}
            </small>
          </h4>
        </button>
      </div>
    </div>
    <div *ngIf="isOwner" class="date__wrapper">
      <h4>
        <small>{{ dayName() }}</small>
      </h4>
      <p>
        <b>{{ formattedDate() }}</b>
      </p>
    </div>
    <div class="image-wrapper" [ngStyle]="{ backgroundImage: 'url(' + triplist.picture_url + ')' }">
    </div>
  </div>
  <div *ngIf="showAuthor" class="buttons__wrapper">
    <div class="user-actions">
      <button type="button" class="user-action" (click)="handleLike()" [class.--active]="triplist.user_liked"
        click-stop-propagation>
        <div class="icon__wrapper" [class.--active]="triplist.bookmark != null">
          <img src="/assets/images/icons/ic_heart_outline.svg" alt="">
          <img src="/assets/images/icons/ic_heart.svg" alt="">
        </div>
        <p>
          <small>{{ triplist.likes_count }}</small>
        </p>
      </button>
      <button *ngIf="!isOwner" type="button" class="user-action" (click)="handleBookmark()"
        [class.--active]="bookmarked" click-stop-propagation>
        <div class="icon__wrapper">
          <img src="/assets/images/icons/ic_bookmark_outline.svg" alt="">
          <img src="/assets/images/icons/ic_bookmark.svg" alt="">
        </div>
      </button>
    </div>
  </div>
  <div class="info__wrapper">
    <div class="content">
      <h4>{{ triplist.name }}</h4>
      <h4>
        <small>
          {{ 'MODELS.TRIPLIST.DURATION' | translate }}: {{ triplist.days.length }} {{ 'MODELS.DAY.OTHER' | translate }}
        </small>
      </h4>
    </div>
    <div *ngIf="isOwner" ngbDropdown #optionsDropdown="ngbDropdown" [autoClose]="false" class="custom-dropdown"
      placement="center">
      <button type="button" class="toggle-dropdown" ngbDropdownToggle click-stop-propagation>
        <img src="/assets/images/icons/ic_dots.svg" alt="">
        <div ngbDropdownMenu aria-labelledby="triplistMenu">
          <ul>
            <li>
              <a [routerLink]="['/triplists', triplist.id]" [queryParams]="{ isEdit: true }" click-stop-propagation>
                {{ 'FORMS.ACTIONS.EDIT' | translate }}
              </a>
            </li>
            <li *ngIf="user.active_subscription">
              <button type="button" (click)="showDeleteConfirmation()" click-stop-propagation>
                {{ 'FORMS.ACTIONS.DELETE' | translate }}
              </button>
            </li>
            <li>
              <button type="button" (click)="duplicate()" click-stop-propagation>
                {{ 'APP.TRIPLISTS.DUPLICATE' | translate }}
              </button>
            </li>
            <li ngbDropdown class="custom-dropdown" placement="right">
              <button type="button" ngbDropdownToggle click-stop-propagation>
                {{ 'COMMON.SHARE' | translate | capitalize }}
              </button>
              <div ngbDropdownMenu aria-labelledby="shareMenu">
                <ul>
                  <li>
                    <share-button [theme]="'material-light'" [button]="'messenger'" [showText]="true" [url]="getUrl()"
                      [autoSetMeta]="false" click-stop-propagation>
                    </share-button>
                  </li>
                  <li>
                    <share-button [theme]="'material-light'" [button]="'whatsapp'" [showText]="true" [url]="getUrl()"
                      [autoSetMeta]="false" click-stop-propagation>
                    </share-button>
                  </li>
                  <li>
                    <share-button [theme]="'material-light'" [button]="'email'" [showText]="true" [url]="getUrl()"
                      [autoSetMeta]="false" click-stop-propagation>
                    </share-button>
                  </li>
                  <li>
                    <share-button [theme]="'material-light'" [button]="'copy'" [showText]="true" [url]="getUrl()"
                      [autoSetMeta]="false" click-stop-propagation>
                    </share-button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </button>
    </div>
  </div>
</a>
<app-delete-triplist (confirmDelete)="confirmDelete($event)"></app-delete-triplist>
