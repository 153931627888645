import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class BookmarksService {
  constructor(private httpClient: HttpClient) {}
  private neighborhoodSelectedSource = new BehaviorSubject<string>('')

  public selectedNeighborhood = this.neighborhoodSelectedSource.asObservable()

  createBookmark(body: object) {
    return this.httpClient.post(`${environment.API}/bookmarks`, {
      bookmark: body,
    })
  }

  updateBookmark(id: string, body: object) {
    return this.httpClient.put(`${environment.API}/bookmarks/${id}`, {
      bookmark: body,
    })
  }

  deleteBookmark(id: string) {
    return this.httpClient.delete(`${environment.API}/bookmarks/${id}`)
  }

  deleteBookmarkFromCollection(bookmarkId: string, collectionId: string) {
    return this.httpClient.delete(
      `${environment.API}/collections/${collectionId}/bookmarks/${bookmarkId}`,
    )
  }

  getTriplistBookmarks(page: string = '1') {
    const params = { page: page }
    return this.httpClient.get(`${environment.API}/bookmarks/triplists`, {
      params: params,
    })
  }

  getPlaces(neighborhoodId: string, interestId: string, page: string) {
    const params = {
      neighborhood_id: neighborhoodId,
      interest_id: interestId,
      page: page,
    }

    return this.httpClient.get(`${environment.API}/bookmarks/places`, {
      params: params,
    })
  }

  getNeighborhoods() {
    return this.httpClient.get(`${environment.API}/bookmarks/neighborhoods`)
  }

  neighborhoodSelected(neighborhoodId: string) {
    this.neighborhoodSelectedSource.next(neighborhoodId)
  }
}
