import { Interest } from "./interest";
import { Bookmark } from "./bookmark";
import { User } from "./user";
import { VisitedPlace } from "./visited-place";

// DEPRECATE: bookmarks attribute
interface PlaceInterface {
  bookmarks: Array<Bookmark>;
  bookmark: Bookmark
  classification: number;
  country_id: string;
  country_name: string;
  cover_picture: string;
  cover_picture_url: string;
  description: object;
  destination_id: string;
  destination_name: string;
  id: string;
  interests: Array<Interest>;
  is_active: boolean;
  is_bookmarked: boolean
  name:string;
  neighborhood_id: string;
  neighborhood_name: string;
  phone_number: string;
  user_visited: boolean;
  local_tourists_visited: Array<User>;
  visited_place: VisitedPlace;
  maps_id: string;
}

export class Place implements PlaceInterface {
  bookmark: Bookmark = new Bookmark()
  bookmarks: Array<Bookmark> = [];
  classification: number = 0;
  country_id: string = '';
  country_name: string = '';
  cover_picture: string;
  cover_picture_url: string = '';
  description: object = {
    en: '',
    'es-MX': ''
  };
  destination_id: string = '';
  destination_name: string = '';
  id: string = '';
  interests: Array<Interest> = [];
  is_active: boolean = true;
  is_bookmarked: boolean = false
  name: string = '';
  neighborhood_id: string = '';
  neighborhood_name: string = '';
  phone_number: string = '';
  user_visited: boolean = false;
  local_tourists_visited: Array<User> = [];
  visited_place: VisitedPlace;
  maps_id: string = '';
}
