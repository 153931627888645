import { Country } from './country'
import { Interest } from './interest'
import { SocialNetwork } from './social_network'
import { Role } from './role'
import { Destination } from './destination'
import { AsideProfile } from './aside-profile'

interface UserInterface {
  active_subscription: boolean
  birthday: string
  business_name: string
  campaign: string
  cover_picture: File
  cover_picture_url: string
  country: Country
  country_id: string
  description: string
  destination: Destination
  email: string
  email_confirmed: boolean
  facebook_user: string
  first_name: string
  gender: number
  hometown: string
  id: string
  instagram_user: string
  interests: Array<Interest>
  is_business_profile: boolean
  is_followed: boolean
  is_local_tourist: boolean
  last_name: string
  local_tourist_description: object
  local_tourist_profession: object
  notifications: object
  password: string
  password_confirmation: string
  phone: string
  profession: string
  profile_picture: File
  profile_picture_url: string
  role: Role
  social_network_name: number
  social_network_id: string
  social_network_token: string
  social_networks: Array<SocialNetwork>
  stats: AsideProfile
  twitter_user: string
  youtube_video_id: object
}

export class User implements UserInterface {
  active_subscription: boolean = false
  birthday: string = ''
  business_name: string = ''
  campaign: string = ''
  country: Country = new Country()
  country_id: string = ''
  cover_picture: File
  cover_picture_url: string = ''
  description: string = ''
  destination: Destination = new Destination()
  email: string = ''
  facebook_user: string = ''
  first_name: string = ''
  gender: number = 0
  hometown: string = ''
  id: string = ''
  instagram_user: string = ''
  interests: Array<Interest> = []
  is_active: boolean = false
  is_business_profile: boolean = false
  is_followed: boolean = false
  is_local_tourist: boolean = false
  email_confirmed: boolean = false
  last_name: string = ''
  local_tourist_description: { en: string; 'es-MX': string } = {
    en: '',
    'es-MX': '',
  }
  local_tourist_profession: { en: string; 'es-MX': string } = {
    en: '',
    'es-MX': '',
  }
  notifications: object = {}
  password: string = ''
  password_confirmation: string = ''
  phone: string = ''
  profession: string = ''
  profile_picture: File
  profile_picture_url: string = ''
  role: Role = new Role()
  social_network_name: number = 0
  social_network_id: string = ''
  social_network_token: string = ''
  social_networks: Array<SocialNetwork> = []
  stats: AsideProfile = new AsideProfile()
  twitter_user: string = ''
  youtube_video_id: { en: string; 'es-MX': string } = {
    en: '',
    'es-MX': '',
  }
}
