import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class GuestGuard implements CanActivate {
  hasPermission: boolean = false;

  constructor(
    private cookies: CookieService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token = this.cookies.get(environment.COOKIE.LANDING);
    const guestToken = this.cookies.get('tl-guest');

    if (token || guestToken) {
      return true;
    } else {
      if (this.checkIfGuest(route)) {
        this.router.navigate([window.location.href])

        return true;
      } else {
        this.router.navigate(['/']);

        return false;
      }
    }
  }

  checkIfGuest(route: ActivatedRouteSnapshot): boolean {
    const token = route.queryParams.guest;

    if (token) {
      this.hasPermission = true;

      this.cookies.put('tl-guest', token);
    }

    return this.hasPermission;
  }
}
