import * as moment from 'moment'
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core'
import { Triplist } from '@models/triplist'
import { BookmarksService } from '@shared/services/bookmarks.service'
import { Toastr } from '@shared/toastr/toastr.service'
import { UsersService } from '@shared/services/users.service'
import { User } from '@models/user'
import { TranslateService } from '@ngx-translate/core'
import { TriplistsService } from '@shared/services/triplists.service'
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { DeleteTriplistComponent } from '../../modals/delete-triplist/delete-triplist.component'

@Component({
  providers: [DeleteTriplistComponent],
  selector: 'app-triplist-card',
  templateUrl: './triplist-card.component.html',
})
export class TriplistCardComponent implements OnInit {
  @Input() triplist: Triplist = new Triplist()
  @Input() showAuthor: boolean = true
  @Output() deleteBookmarkCallback: EventEmitter<object> = new EventEmitter()
  @Output() followUserCallback: EventEmitter<object> = new EventEmitter()
  @Output() unfollowUserCallback: EventEmitter<object> = new EventEmitter()
  @Output() deleteTriplist: EventEmitter<object> = new EventEmitter()
  @Output() duplicateTriplist: EventEmitter<object> = new EventEmitter()
  @ViewChild('optionsDropdown') optionsDropdown: NgbDropdown

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target) && this.isOwner) {
      this.optionsDropdown.close()
    }
  }

  bookmarked: boolean = false
  isOwner: boolean = false
  user: User = new User()

  constructor(
    private _bookmarks: BookmarksService,
    private _triplists: TriplistsService,
    private _users: UsersService,
    private elementRef: ElementRef,
    private ngxSmartModalService: NgxSmartModalService,
    private toastr: Toastr,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.checkBookmark()
    this.getCurrentUser()
  }

  getCurrentUser() {
    this._users.current.subscribe((user) => {
      this.user = user

      this.checkIfIsOwner()
    })
  }

  checkIfIsOwner() {
    this.isOwner = this.user.id == this.triplist.user.id
  }

  checkBookmark() {
    this.bookmarked = this.triplist.bookmark != null
  }

  confirmDelete(triplist: Triplist) {
    this._triplists.deleteTriplist(triplist.id).subscribe(
      (_) => {
        this.deleteTriplist.emit()
        this.ngxSmartModalService.get('deleteTriplistModal').close()
        window.location.reload()
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  showDeleteConfirmation() {
    this.optionsDropdown.close()

    let modalData = { triplist: this.triplist }
    this.ngxSmartModalService.setModalData(modalData, 'deleteTriplistModal')
    this.ngxSmartModalService.get('deleteTriplistModal').open()
  }

  followLocalTourist(user) {
    const body = { following_id: user.id }

    this._users.followUser(body).subscribe(
      (response) => {
        user.is_followed = true

        this.followUserCallback.emit(user)
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  handleBookmark() {
    if (this.bookmarked) {
      this.removeFromBookmarks()
    } else {
      this.addToBookmarks()
    }
  }

  addToBookmarks() {
    const body = { triplist_id: this.triplist.id }

    this._bookmarks.createBookmark(body).subscribe(
      (response) => {
        this.bookmarked = true
        this.triplist.bookmark = response['bookmark']
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  unFollowLocalTouris(user) {
    this._users.unfollowUser(user.id).subscribe(
      (response) => {
        user.is_followed = false

        this.unfollowUserCallback.emit(user)
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  removeFromBookmarks() {
    this._bookmarks.deleteBookmark(this.triplist.bookmark.id).subscribe(
      (_) => {
        this.bookmarked = false
        this.triplist.bookmark = null

        this.deleteBookmarkCallback.emit()
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  dayName() {
    const dayName = moment(this.triplist.start_date)
      .format('dddd')
      .toUpperCase()

    return this.translate.instant('COMMON.DAYS.' + dayName).substring(0, 3)
  }

  formattedDate() {
    const date = moment(this.triplist.start_date)
    const day = date.format('DD')
    const monthName = date.format('MMMM').toUpperCase()
    const month = this.translate
      .instant('COMMON.MONTHS.' + monthName)
      .substring(0, 3)

    return day + ' ' + month
  }

  handleLike() {
    if (this.triplist.user_liked) {
      this.dislike()
    } else {
      this.like()
    }
  }

  like() {
    this._triplists.like(this.triplist.id).subscribe(
      (response) => {
        this.triplist = response['triplist']
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  dislike() {
    this._triplists.dislike(this.triplist.id).subscribe(
      (response) => {
        this.triplist = response['triplist']
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  getUrl() {
    const origin = window.location.origin

    return origin + '/triplists/' + this.triplist.id
  }

  duplicate() {
    this._triplists.duplicate(this.triplist.id).subscribe(
      (response) => {
        this.duplicateTriplist.emit(response['triplist'])
        this.optionsDropdown.close()
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }
}
