import { Attraction } from "./attractions";
import { HistoricalFigure } from "./historical-figure";
import { Tradition } from "./tradition";
import { TypicalFood } from "./typical-food";
import { Neighborhood } from "./neighborhood";

interface DestinationInterface {
  id: string;
  name: string;
  description: object;
  country_id: string;
  country_name: string;
  cover_picture_url: string;
  cover_picture: string;
  neighborhoods: Array<Neighborhood>;
  iconic_neighborhoods: Array<Neighborhood>;
  attractions: Array<Attraction>;
  historical_figures: Array<HistoricalFigure>;
  traditions: Array<Tradition>;
  typical_foods: Array<TypicalFood>;
}

export class Destination implements DestinationInterface{
  id: string = '';
  name: string = '';
  description: object = {
    en:  '',
    'es-MX': ''
  };
  country_id: string = '';
  country_name: string = '';
  cover_picture: string = '';
  cover_picture_url: string = '';
  neighborhoods: Array<Neighborhood> = [];
  iconic_neighborhoods: Array<Neighborhood> = [];
  attractions: Array<Attraction> = [];
  historical_figures: Array<HistoricalFigure> = [];
  traditions: Array<Tradition> = [];
  typical_foods: Array<TypicalFood> = [];
}
