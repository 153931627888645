<ngx-smart-modal
  #shareTriplistModal
  identifier="shareTriplistModal"
  customClass="share-triplist-modal custom-modal nsm-centered">
  <div class="modal-header --no-border">
    <h3>
      {{ 'APP.TRIPLISTS.SHARE_MODAL.TITLE' | translate }}
    </h3>
    <p>
      {{ 'APP.TRIPLISTS.SHARE_MODAL.QUOTE' | translate }}
    </p>
    <h4>
      <small>
        - Christopher McCandles -
      </small>
    </h4>
  </div>
  <div class="modal-body container">
    <div class="row share-link">
      <div class="col-8">
        <label for="share-link">
          <span class="input-name">
            {{ 'APP.TRIPLISTS.SHARE_MODAL.SHARE_LINK' | translate }}
          </span>
          <input type="text" name="share-link" [value]="getUrl()">
        </label>
      </div>
      <div class="col-4 d-flex align-items-end">
        <button
          ngxClipboard
          [cbContent]="getUrl()"
          class="button--primary w-100"
          type="button">
          {{ 'COMMON.COPY' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body container">
    <form [formGroup]="guestForm" (ngSubmit)="onSubmit(guestForm)">
      <div class="row">
        <div class="col-8">
          <app-input
            type="tag-input"
            id="guests"
            [parentForm]="guestForm"
            [placeholder]="'APP.TRIPLISTS.SHARE_MODAL.PEOPLE_PLACEHOLDER' | translate"
            [labelName]="'APP.TRIPLISTS.SHARE_MODAL.PEOPLE_LABEL' | translate"
            tagIdentifier="email"
            tagDisplay="email">
          </app-input>
        </div>
        <div class="col-4 d-flex align-items-end">
          <app-input
            type="select"
            id="can_edit"
            [parentForm]="guestForm"
            [collection]="permissions">
          </app-input>
        </div>
        <div *ngIf="guestForm.get('guests').value.length > 0" class="col-12">
          <app-input
            type="textarea"
            id="note"
            [parentForm]="guestForm"
            [placeholder]="'APP.TRIPLISTS.SHARE_MODAL.NOTE_PLACEHOLDER' | translate">
          </app-input>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <button
            type="button"
            class="button--stroke w-100"
            (click)="modals.get('shareTriplistModal').close()">
            {{ 'FORMS.ACTIONS.CANCEL' | translate }}
          </button>
        </div>
        <div class="col-8">
          <button
            type="submit"
            class="button--primary w-100"
            [class.--disabled]="loading">
            {{ 'APP.TRIPLISTS.SHARE_MODAL.SEND' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ngx-smart-modal>