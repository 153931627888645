import { Place } from "./place";

interface NeighborhoodInterface {
  id: string;
  name: string;
  country_id: string;
  destination_id: string;
  cover_picture: string;
  cover_picture_url: string;
  places: Array<Place>;
  description: object;
}
export class Neighborhood implements NeighborhoodInterface {
  id: string = '';
  name: string = '';
  country_id: string = '';
  destination_id: string = '';
  cover_picture: string = '';
  cover_picture_url: string = '';
  places: Array<Place> = [];
  description: object = {
    en: '',
    'es-MX': ''
  }
}