<div class="content">
  <h4 class="title">{{ 'APP.PROFILE.TRAVEL_WISDOM' | translate }}</h4>
  <ul class="stats">
    <li>
      <p>{{ 'MODELS.TRIPLIST.OTHER' | translate | capitalize }}</p>
      <p>{{ user.stats.triplists }}</p>
    </li>
    <li>
      <p>{{ 'MODELS.COLLECTION.OTHER' | translate | capitalize }}</p>
      <p>{{ user.stats.collections }}</p>
    </li>
  </ul>
</div>
<div class="content">
  <h4 class="title">{{ 'APP.PROFILE.RECOMMENDATIONS' | translate }}</h4>
  <ul class="places">
    <li *ngFor="let place of suggestedPlaces">
      <a [routerLink]="['/explore/destinations/', place.destination_id, 'neighborhoods', place.neighborhood_id, 'places', place.id]">
        <p>{{ place.neighborhood_name }}</p>
        <div class="place-info">
          <div class="image" [ngStyle]="{ backgroundImage: 'url(' + place.cover_picture_url + ')' }">
          </div>
          <div>
            <h4>
              <small>
                {{ place.name }}
              </small>
            </h4>
            <h4>
              <small>
                {{ 'APP.EXPLORE.WORTH_THE_VISIT' | translate }}:
              </small>
            </h4>
          </div>
        </div>
      </a>
    </li>
    <li *ngIf="placesPagination.nextPage != null" class="next-page">
      <button type="button" class="button--secondary" (click)="getPlaces()">
        {{ 'COMMON.LOAD_MORE' | translate }}
      </button>
    </li>
  </ul>
</div>
