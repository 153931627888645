import { Component, OnInit, Input } from '@angular/core'
import { NotificationsService } from '@shared/services/notifications.service'
import { Notification } from '@models/notification'
import { Toastr } from '@shared/toastr/toastr.service'
import { Pagination } from '@models/pagination'
import { CookieService } from 'ngx-cookie'

import { LoadersCSS } from 'ngx-loaders-css'
import { User } from '../../../../models/user'

@Component({
  selector: 'app-my-notifications',
  templateUrl: './my-notifications.component.html',
})
export class MyNotificationsComponent implements OnInit {
  @Input() isVisible: boolean = false
  @Input() user: User = new User()
  notifications: Array<Notification> = []
  interests = []
  pagination: Pagination = new Pagination()
  language: string = this.cookies.get('tl-lang') || 'en'
  days: number
  loader: LoadersCSS = 'line-scale-pulse-out'
  color = 'rgb(2,15,255)'

  constructor(
    private _notification: NotificationsService,
    private toastr: Toastr,
    private cookies: CookieService,
  ) {}

  ngOnInit() {
    this.interests = this.user.interests
    this.getUserNotifications()
  }

  /*getUserNotifications () {
    const page = this.pagination.nextPage || 1;

    this._notification.getNotifications(page).subscribe((
      { notifications, pagination }:
      { notifications: Array<Notification>, pagination: Pagination }
      ) => {
      this.pagination = pagination;
      notifications.forEach((notification: Notification) => {
        this.notifications.push(notification);
      });
    }, errors => {
      this.toastr.error(errors);
    });
  }*/

  getUserNotifications() {
    if (this.pagination.nextPage) {
      this.pagination.isLoading = true
      this._notification.getNotifications(this.pagination.nextPage).subscribe(
        (response: any) => {
          if (this.pagination.nextPage != response.meta.pagination.next_page) {
            response['notifications'].forEach((notify) => {
              this.notifications.push(notify)
            })
            this.pagination.nextPage = response.meta.pagination.next_page
            this.pagination.isLoading = false
          }
        },
        (errors) => {
          this.toastr.error(errors)
        },
      )
    }
  }
}
