import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'

@Injectable({ providedIn: 'root' })
export class InterestsService {
  constructor(private httpClient: HttpClient) {}

  createInterest(value) {
    return this.httpClient.post(`${environment.API}/interests`, value)
  }

  updateInterest(id, value) {
    return this.httpClient.put(`${environment.API}/interests/${id}`, value)
  }

  getAllInterests(q: string = '') {
    const params = { q: q }

    return this.httpClient.get(`${environment.API}/interests`, {
      params: params,
    })
  }

  getAllInterestsDashboard(
    page: string = '1',
    sortField: string = '',
    sortOrder: string = '',
  ) {
    const params = { page, sortField, sortOrder }

    return this.httpClient.get(`${environment.API}/interests`, {
      params: params,
    })
  }

  getInterest(id: string) {
    return this.httpClient.get(`${environment.API}/interests/${id}`)
  }

  deleteInterest(id: string) {
    return this.httpClient.delete(`${environment.API}/interests/${id}`)
  }
}
