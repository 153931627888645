import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  Input,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { InputComponent } from '@shared/components/inputs/input/input.component'
import { Toastr } from '@shared/toastr/toastr.service'
import { CollectionsService } from '@shared/services/collections.service'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { TranslateService } from '@ngx-translate/core'
import { Collection } from '@models/collection'
import { FormUtilities } from '@utilities/form-utilities'
import { Router } from '@angular/router'

@Component({
  selector: 'app-create-collection-modal',
  templateUrl: './create-collection.component.html',
  providers: [CollectionsService],
})
export class CreateCollectionModalComponent implements OnInit {
  @ViewChildren(InputComponent) inputComponents: QueryList<InputComponent>
  @Input() isEdit: boolean = false
  @Output() collectionsUpdated: EventEmitter<object> = new EventEmitter()
  @Output() callback: EventEmitter<object> = new EventEmitter()

  collection: Collection = new Collection()
  newCollectionForm: FormGroup
  image: string = ''

  constructor(
    private _collections: CollectionsService,
    private formBuilder: FormBuilder,
    private modal: NgxSmartModalService,
    private router: Router,
    private toastr: Toastr,
    private translate: TranslateService,
  ) {
    this.newCollectionForm = this.formBuilder.group({
      name: ['', Validators.required],
      picture: [''],
    })
  }

  ngOnInit() {}

  getCollection() {
    const modal = this.modal.get('createCollectionModal')

    if (modal.hasData()) {
      const collection = modal.getData()
      this.collection = collection
      this.image = this.collection.picture_url
      this.isEdit = true

      this.newCollectionForm.patchValue(collection)
    }
  }

  onSubmit({ invalid }: { invalid: Boolean }) {
    if (invalid) {
      return this.inputComponents.forEach((input) => input.triggerError())
    }

    if (this.isEdit) {
      this.updateCollection()
    } else {
      this.createCollection()
    }
  }

  closeModal() {
    this.modal.get('createCollectionModal').close()
    this.reset()
  }

  createCollection() {
    //const payload = this.newCollectionForm.value;

    const payload = FormUtilities.generateFormData(
      this.newCollectionForm,
      'collection',
    )

    this._collections.createCollection(payload).subscribe(
      (response) => {
        const message = this.translate.instant('FORMS.MESSAGES.SAVED')

        this.collectionsUpdated.emit()
        this.modal.closeLatestModal()
        this.toastr.success(message)
        this.callback.emit(response['collection'])
        this.newCollectionForm.reset()
        this.router.navigate(['/bookmarks/places/collections'])
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  updateCollection() {
    //const body = this.newCollectionForm.value;
    const id = this.collection.id

    const body = FormUtilities.generateFormData(
      this.newCollectionForm,
      'collection',
    )

    this._collections.updateCollection(id, body).subscribe(
      (response) => {
        const message = this.translate.instant('FORMS.MESSAGES.SAVED')

        this.collectionsUpdated.emit(response['collection'])
        this.toastr.success(message)
        this.modal.closeLatestModal()
      },
      (errors) => {
        this.toastr.error(errors)
      },
    )
  }

  fileChanged(event) {
    if (!event) {
      event = window.event
    }

    const evt = event.target || event.srcElement
    const file = evt.files

    if (file && file[0]) {
      const fileSize = file[0].size / 1024 / 1024

      if (fileSize <= 30) {
        const reader = new FileReader()

        reader.onload = (e: any) => {
          this.image = e.target.result
        }

        reader.readAsDataURL(file[0])
        this.newCollectionForm.patchValue({ picture: file[0] })
      }
    }
  }

  reset() {
    this.isEdit = false
    this.collection = null
    this.image = ''
    this.modal.resetModalData('createCollectionModal')

    this.newCollectionForm.reset()
  }
}
