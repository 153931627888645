import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { User } from '@models/user'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { Subscription } from 'rxjs'
import { ApplicationService } from '../../../../services/application.service'

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
})
export class BasicInformationComponent implements OnInit, OnDestroy {
  @Input() user: User = new User()
  subscriptions: Subscription = new Subscription()
  youtubeLink: SafeResourceUrl
  currentLanguage: string

  constructor(
    private applicationService: ApplicationService,
    public modal: NgxSmartModalService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.applicationService.currentLanguage.subscribe((language: string) => {
        this.currentLanguage = language
        this.youtubeLink = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://www.youtube.com/embed/${this.user.youtube_video_id[language]}?rel=0`,
        )
      }),
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  openAboutModal() {
    this.modal.get('localTouristAboutModal').open()
  }

  openModal() {
    this.modal.get('localTouristVideoModal').open()
  }

  videoUrl() {
    return this.youtubeLink
  }
}
