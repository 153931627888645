import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dash-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnInit {
  @Input() title: string;
  @Input() items: Array<any> = [];
  @Input() options: Array<object> = [];
  @Input() clickRedirect: string;
  @Input() createUrl: string;
  @Output() create: EventEmitter<object> = new EventEmitter();
  @Output() edit: EventEmitter<object> = new EventEmitter();
  @Output() destroy: EventEmitter<object> = new EventEmitter();

  language: string = this.cookies.get('tl-lang') || 'en';

  constructor(
    private cookies: CookieService,
    private router: Router
  ) {}

  ngOnInit() {}

  emitCreate() {
    this.create.emit();
  }

  emitEdit(item: any) {
    this.edit.emit(item);
  }

  emitDestroy(item: any) {
    this.destroy.emit(item);
  }

  getText(item: object, entry: object) {
    const keys = entry['keys'];
    const value = keys ? this.multiKeyText(item, entry) : this.singleKeyText(item, entry);

    return entry['localized'] ? value[this.language] : value;
  }

  singleKeyText(item: object, entry: object) {
    const keys = entry['key'].split('.');
    const value = keys.length > 1 ? this.isNestedKey(keys, item) : item[keys[0]];

    return value;
  }

  multiKeyText(item: object, entry: object) {
    let value = [];

    entry['keys'].forEach(key => {
      const keyAry = key.split('.');

      if (keyAry.length > 1) {
        value.push(this.isNestedKey(keyAry, item));
      } else {
        value.push(item[keyAry[0]]);
      }
    });

    return value.join(entry['joinKeys'] || ' ');
  }

  isNestedKey(keys: Array<string>, item: object) {
    let value = item;

    keys.forEach(key => value = value[key]);

    return value;
  }

  getBooleanValue(item: object, entry: object) {
    const value = item[entry['key']];

    switch(value) {
      case true:
        return 'active';
      case false:
        return 'inactive';
      case null:
        return 'disabled';
    }
  }

  handleRedirect(item: object) {
    if (this.clickRedirect) {
      const url = this.clickRedirect.split('/');

      this.router.navigate(['/dashboard', ...url, item['id']]);
    }
  }

  hasObservers(emitName: string) {
    return this[emitName].observers.length > 0;
  }

  createRedirect() {
    this.router.navigate(['/dashboard', this.createUrl, 'new']);
  }

  hasControls() {
    return this.hasObservers('edit') || this.hasObservers('destroy');
  }
}
