interface BookmarkInterface {
  id: string;
  collection_id: string;
  collection_name: string;
  place_id: string;
  place_name: string;
  place_picture_url: string;
  triplist_id: string;
  triplist_name: string;
}

export class Bookmark implements BookmarkInterface {
  id: string = '';
  collection_id: string = '';
  collection_name: string = '';
  place_id: string = '';
  place_name: string = '';
  place_picture_url: string = '';
  triplist_id: string = '';
  triplist_name: string = '';
}