import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import { User } from '@models/user'

@Injectable({ providedIn: 'root' })
export class VisitedPlacesService {
  constructor(private httpClient: HttpClient) {}

  getUserVisitedPlace(place_id: string) {
    return this.httpClient.get(
      `${environment.API}/places/${place_id}/user_visited`,
    )
  }

  createVisitedPlace(placeId: string) {
    const body = { visited_place: { place_id: placeId } }

    return this.httpClient.post(`${environment.API}/visited_places`, body)
  }

  deleteVisitedPlace(placeId: string) {
    return this.httpClient.delete(
      `${environment.API}/visited_places/${placeId}`,
    )
  }

  getGuestSuggestedPlaces(user: User, page: string) {
    const params = { user: user.id, page: page.toString() }

    return this.httpClient.get(`${environment.API}/guest_suggested_places`, {
      params: params,
    })
  }

  getSuggestedPlaces(page: string) {
    const params = { page: page.toString() }

    return this.httpClient.get(`${environment.API}/suggested_places`, {
      params: params,
    })
  }

  updateVisitedPlace(id: string, value: object) {
    const body = { visited_place: value }

    return this.httpClient.put(`${environment.API}/visited_places/${id}`, body)
  }
}
