import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Notification } from "@models/notification";
import { map } from 'rxjs/operators';
import { Pagination } from '@models/pagination';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor ( private httpClient: HttpClient ) {}

  /*getNotifications(page: string) {
    return this.httpClient.get(`${environment.API}/notifications?page=${page}`).pipe(
      map(response => {
        const notifications = response['notifications'];
        const pagination = new Pagination(response['meta']['pagination']);

        return { notifications, pagination };
      }),
      map((
        { notifications, pagination }:
        { notifications: Array<Notification>, pagination: Pagination }
      ) => {
        const notificationModels = notifications.map((notification: Notification) => {
          return new Notification(notification);
        });

        return { notifications: notificationModels, pagination };
      })
    )
  }*/
  getNotifications(page: string) {
    return this.httpClient.get(`${environment.API}/notifications?page=${page}`)
  }
}
