import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputComponent } from '@shared/components/inputs/input/input.component';
import { TriplistsService } from 'src/app/pages/clean/triplists/triplists.service';
import { Triplist } from '@models/triplist';
import { Toastr } from '@shared/toastr/toastr.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-share-triplist-modal',
  templateUrl: './share-triplist.component.html'
})
export class ShareTriplistComponent implements OnInit {
  @ViewChildren(InputComponent) inputComponents: QueryList<InputComponent>;

  guestForm: FormGroup;
  loading: boolean = false;
  permissions: Array<object> = [
    { id: 0, name: this.translate.instant('APP.TRIPLISTS.SHARE_MODAL.CAN_VIEW') },
    { id: 1, name: this.translate.instant('APP.TRIPLISTS.SHARE_MODAL.CAN_EDIT') }
  ];
  triplist: Triplist = new Triplist();

  constructor(
    private _triplists: TriplistsService,
    private formBuilder: FormBuilder,
    public modals: NgxSmartModalService,
    private toastr: Toastr,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getTriplist();
  }

  initForm() {
    this.guestForm = this.formBuilder.group({
      guests: [[]],
      can_edit: [0, Validators.required],
      note: ['']
    });
  }

  getTriplist() {
    this._triplists.current.subscribe(triplist => {
      this.triplist = triplist;

      if (triplist.id) {
        this.setGuestsInformation();
      }
    });
  }

  setGuestsInformation() {
    if (this.triplist.guests.length > 0) {
      // HACK (Raúl Amador): As all guests have the same permission per triplist,
      //                     I'll take the first value to patch form.
      const canEdit = this.triplist.guests[0].canEdit ? 1 : 0;

      this.guestForm.patchValue({
        guests: this.triplist.guests.map(guest => guest.email),
        can_edit: canEdit
      });
    }
  }

  onSubmit({ invalid }: { invalid: boolean }) {
    if (invalid) {
      return this.inputComponents.forEach(input => input.triggerError());
    }

    this.sendInvitations();
  }

  sendInvitations() {
    const id = this.triplist.id;
    const value = this.formatForm();
    this.loading = true;

    this._triplists.inviteGuests(id, value).subscribe(_ => {
      const message = this.translate.instant('APP.TRIPLISTS.SHARE_MODAL.SENT_TOAST');
      this.loading = false;

      this.modals.get('shareTriplistModal').close();
      this.toastr.success(message);
    }, errors => {
      this.toastr.error(errors);
    });
  }

  formatForm() {
    const canEdit = this.guestForm.get('can_edit').value;
    const guests = this.guestForm.get('guests').value.map(guest => {
      guest['can_edit'] = canEdit;

      return guest;
    });
    this.guestForm.patchValue({ guests: guests });

    return this.guestForm.value;
  }

  getUrl() {
    return window.location.href;
  }
}
