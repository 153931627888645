import { Permission } from "./permission";

interface RoleInterface {
  access_level: number;
  id: string;
  name: string;
  permissions: Array<Permission>
}

export class Role implements RoleInterface {
  access_level: number = 2;
  id: string = '';
  name: string = '';
  permissions: Array<Permission> = [];
}
