import { Component, OnInit, Input } from '@angular/core';
import { Destination } from '@models/destination';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-destination-card',
  templateUrl: './destination-card.component.html'
})
export class DestinationCardComponent implements OnInit {
  @Input() destination: Destination = new Destination();
  @Input() outOfExplore: boolean = false;
  @Input() routeId: boolean = false;

  language: string = this.cookies.get('tl-lang') || 'en';

  constructor(private cookies: CookieService) {}

  ngOnInit() {}

  setUrl() {
    const id = this.destination.id;

    if (this.outOfExplore) {
      return this.routeId ? [id] : ['/explore/destinations', id];
    } else {
      return this.routeId ? [id] : ['destinations', id];
    }
  }
}
