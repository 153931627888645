import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { Collection } from '@models/collection'
import { environment } from '@environments/environment'
import { Place } from '@models/place'

@Injectable({ providedIn: 'root' })
export class CollectionsService {
  constructor(private httpClient: HttpClient) {}

  private currentCollectionPlacesSource = new BehaviorSubject<Place[]>([])

  public currentCollectionPlaces = this.currentCollectionPlacesSource.asObservable()

  createCollection(payload) {
    return this.httpClient.post(`${environment.API}/collections`, payload)
  }

  currentCollectionPlacesUpdate(collectionPlaces: Place[]) {
    this.currentCollectionPlacesSource.next(collectionPlaces)
  }

  updateCollection(id: string, body) {
    return this.httpClient.put(`${environment.API}/collections/${id}`, body)
  }

  deleteCollection(id: string) {
    return this.httpClient.delete(`${environment.API}/collections/${id}`)
  }

  getUserCollections(page: string = '1') {
    const params = { page: page }

    return this.httpClient.get(`${environment.API}/collections`, { params })
  }

  getCollection(id: string) {
    return this.httpClient.get(`${environment.API}/collections/${id}`)
  }

  getPlaces(id: string, neighborhoodId: string, interestId: string) {
    let params = {}

    if (neighborhoodId) {
      params['neighborhood_id'] = neighborhoodId
    }
    if (interestId) {
      params['interest_id'] = interestId
    }

    return this.httpClient.get(`${environment.API}/collections/${id}/places`, {
      params,
    })
  }
}
