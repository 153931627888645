import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Country } from '@models/country'
import { Destination } from '@models/destination'
import { Neighborhood } from '@models/neighborhood'
import { Place } from '@models/place'
import { User } from '@models/user'
import { TranslateService } from '@ngx-translate/core'
import { CollectionsService } from '@shared/services/collections.service'
// import { ClassificationEnum } from "@shared/enums/classification.enum";
import { LocationsService } from '@shared/services/locations.service'
import { UsersService } from '@shared/services/users.service'
import { Toastr } from '@shared/toastr/toastr.service'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-create-place-recommended-by',
  templateUrl: './create-place-recommended-by.component.html',
})
export class CreatePlaceRecommendedByComponent implements OnInit, OnDestroy {
  @Output() newPlace: EventEmitter<Place> = new EventEmitter<Place>()
  // classifications: Array<ClassificationEnum>;
  countries: Array<Country>
  destinations: Array<Destination>
  firstPlaceFromCollection: Place
  formData = new FormData()
  image: string
  isSubmitted: boolean
  isLoading: boolean = false
  neighborhoods: Array<Neighborhood>
  newPlaceRecommendedByForm: FormGroup = this.fb.group({
    name: [null, Validators.required],
    country_id: [null, Validators.required],
    description: {},
    destination_id: [null, Validators.required],
    neighborhood_id: [null, Validators.required],
    phone_number: [null, Validators.required],
    cover_picture: [null, Validators.required],
    classification: [null, Validators.required],
    maps_id: [null, Validators.required],
  })
  subscriptions: Subscription = new Subscription()
  user: User = null

  constructor(
    private fb: FormBuilder,
    private collectionsService: CollectionsService,
    private locationService: LocationsService,
    public modal: NgxSmartModalService,
    private toastr: Toastr,
    private translate: TranslateService,
    private usersService: UsersService,
  ) {}

  ngOnInit() {
    this.getCurrentCollection()
    this.getCountries()
    this.getCurrentUser()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  cancel() {
    this.modal.closeLatestModal()
    this.newPlaceRecommendedByForm.reset()
    this.getCurrentCollection()
    this.getCurrentUser()
  }

  countryChanged(id: string) {
    this.newPlaceRecommendedByForm.patchValue({
      neighborhood_id: '',
      destination_id: '',
    })

    this.getDestinationsByCountry(id)
  }

  destinationChanged(id: string) {
    this.newPlaceRecommendedByForm.patchValue({ neighborhood_id: '' })
    this.getNeighborhoodsByDestination(id)
  }

  fileChanged(event) {
    if (!event) {
      event = window.event
    }

    const evt = event.target || event.srcElement
    const file = evt.files

    if (file && file[0]) {
      const fileSize = file[0].size / 1024 / 1024

      if (fileSize <= 30) {
        const reader = new FileReader()

        reader.onload = (e: any) => {
          this.image = e.target.result
        }

        reader.readAsDataURL(file[0])
        this.newPlaceRecommendedByForm.patchValue({ cover_picture: file[0] })
      }
    }
  }

  getCountries() {
    this.locationService.getAllCountries().subscribe((response) => {
      this.countries = response['countries']
    }),
      (error) => {
        this.toastr.error(error)
      }
  }

  getCurrentCollection() {
    this.subscriptions.add(
      this.collectionsService.currentCollectionPlaces.subscribe((places) => {
        this.firstPlaceFromCollection = places[0]
        if (this.firstPlaceFromCollection?.country_id) {
          this.newPlaceRecommendedByForm.patchValue({
            country_id: this.firstPlaceFromCollection.country_id,
          })

          this.getDestinationsByCountry(
            this.firstPlaceFromCollection.country_id,
          )
        }
      }),
    )
  }

  getCurrentUser() {
    this.subscriptions.add(
      this.usersService.current.subscribe((user) => {
        this.user = user
      }),
    )
  }

  getDestinationsByCountry(id: string) {
    this.locationService.getDestinationsByCountry(id).subscribe(
      (response) => {
        this.destinations = response['destinations']
        if (this.firstPlaceFromCollection?.destination_id) {
          this.newPlaceRecommendedByForm.patchValue({
            destination_id: this.firstPlaceFromCollection.destination_id,
          })
          this.getNeighborhoodsByDestination(
            this.firstPlaceFromCollection.destination_id,
          )
        }
      },
      (error) => this.toastr.error(error),
    )
  }

  getNeighborhoodsByDestination(id: string) {
    this.locationService.getNeighborhoodsByDestination(id).subscribe(
      (response) => {
        this.neighborhoods = response['neighborhoods']
      },
      (error) => this.toastr.error(error),
    )
  }

  onSubmit({ value, invalid }: { value: Place; invalid: boolean }) {
    this.formData = new FormData()
    // this.isLoading = true
    if (invalid) {
      return (this.isSubmitted = true)
    }
    const classification = value.classification.toString()
    const description = ''
    const interestsArray = [
      '777e6ad8-8bd2-47b9-b295-52f1be5ed7c0',
      '13ca9074-0565-4e81-9909-666d1a494633',
    ]

    this.formData.append('place[classification]', classification)
    this.formData.append('place[country_id]', value.country_id)
    this.formData.append(
      'place[cover_picture]',
      value.cover_picture,
      value.name,
    )
    this.formData.append('place[description[en]]', description)
    this.formData.append('place[description[es-MX]]', description)
    this.formData.append('place[destination_id]', value.destination_id)
    interestsArray.forEach((interest) => {
      this.formData.append('place[interests[][id]]', interest)
    })
    this.formData.append('place[maps_id]', value.maps_id)
    this.formData.append('place[name]', value.name)
    this.formData.append('place[neighborhood_id]', value.neighborhood_id)
    this.formData.append('place[phone_number]', value.phone_number)

    this.locationService.createPlaceRecommendedBy(this.formData).subscribe(
      (res) => {
        this.isLoading = false
        const message = this.translate.instant('APP.PLACES.NEW_PLACE_ADDED')
        this.toastr.success(message)
        this.newPlace.emit(res['place'])
      },
      (err) => {
        this.toastr.error(err)
      },
    )
  }
}
