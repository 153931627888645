<div class="my__notifications__wrapper">
  <ul infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [infiniteScrollDisabled]="isVisible" (scrolled)="getUserNotifications()">
    <li *ngIf="interests.length === 0">
      <a [routerLink]="['/my-profile/settings/my-interests/']">
        <div class="user__wrapper">
        </div>
        <div class="navigation__wrapper">
          <div class="description__wrapper">
            <h3>
              {{'APP.NOTIFICATIONS.PENDING' | translate}}
            </h3>
            <h4>
              <small>
                {{'COMMON.ERRORS.INTERESTS_NOT_FOUND' | translate}}
              </small>
            </h4>
          </div>
          <div class="time-ago__wrapper">
            <div class="time-ago-notification">
              <h4>
                <small class="button--primary">
                  {{'APP.PROFILE.SETTINGS.MY_INTERESTS.ADD_INTEREST' | translate}}
                </small>
              </h4>
            </div>
          </div>
        </div>
      </a>
    </li>
    <li *ngFor="let notify of notifications">
      <a *ngIf="notify.notification_type == 0" [routerLink]="['/triplists/' + notify.triplist_id ]">
        <div class="user__wrapper">
          <app-user-profile-picture [user]="notify.reference_user"></app-user-profile-picture>
        </div>
        <div class="navigation__wrapper">
          <div class="description__wrapper">
            <h3>
              {{ notify.user.first_name }} {{ notify.user.last_name }}
            </h3>
            <h4>
              <small>
                {{ notify.message[language]}}
              </small>
            </h4>
          </div>
          <div class="time-ago__wrapper">
            <div class="icon-notification">
              <img src="/assets/images/icons/ic_compass_grey.svg" alt="">
            </div>
            <div class="time-ago-notification">
              <h4>
                <small>
                  {{ notify.created_at | dateAgo }}
                </small>
              </h4>
            </div>
          </div>
        </div>
      </a>
      <a *ngIf="notify.notification_type == 1" [routerLink]="['/local-tourists/' + notify.reference_user.id ]">
        <div class="user__wrapper">
          <app-user-profile-picture [user]="notify.reference_user"></app-user-profile-picture>
        </div>
        <div class="navigation__wrapper">
          <div class="description__wrapper">
            <h3>
              {{ notify.user.first_name }} {{ notify.user.last_name }}
            </h3>
            <h4>
              <small>
                {{ notify.message[language]}}
              </small>
            </h4>
          </div>
          <div class="time-ago__wrapper">
            <div class="icon-notification">
              <img src="/assets/images/icons/ic_mail_grey.svg" alt="">
            </div>
            <div class="time-ago-notification">
              <h4>
                <small>
                  {{ notify.created_at | dateAgo }}
                </small>
              </h4>
            </div>
          </div>
        </div>
      </a>
      <a *ngIf="notify.notification_type == 2" [routerLink]="['/triplist/' + notify.triplist_id ]">
        <div class="user__wrapper">
          <app-user-profile-picture [user]="notify.user"></app-user-profile-picture>
        </div>
        <div class="navigation__wrapper">
          <div class="description__wrapper">
            <h3>
              {{ notify.user.first_name }} {{ notify.user.last_name }}
            </h3>
            <h4>
              <small>
                {{ notify.message[language]}}
              </small>
            </h4>
          </div>
          <div class="time-ago__wrapper">
            <div class="icon-notification">
              <img src="/assets/images/icons/ic_calendar_grey.svg" alt="">
            </div>
            <div class="time-ago-notification">
              <h4>
                <small>
                  {{ notify.created_at | dateAgo }}
                </small>
              </h4>
            </div>
          </div>
        </div>
      </a>
    </li>
  </ul>
  <div *ngIf="pagination.isLoading">
    <loaders-css [color]="color" [loader]="loader" [scale]="1" [bgColor]="bgColor"></loaders-css>
  </div>
  <!-- <div
    *ngIf="pagination.currentPage != pagination.totalPages && isVisible"
    class="pagination-wrapper">
    <button class="button--primary" type="button" (click)="getUserNotifications()">
        {{ 'APP.NOTIFICATIONS.LOAD_MORE' | translate }}
    </button>
  </div> -->
</div>
