import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'turista local';

  constructor(
    translate: TranslateService,
    private cookies: CookieService
  ) {
    translate.setDefaultLang('en');

    if (this.cookies.get('tl-lang')) {
      translate.use(this.cookies.get('tl-lang'));
    }
  }
}
